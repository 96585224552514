 .article-wrapper {
 margin-left:   -15px;
 margin-right:  -15px;


    @media(min-width:@screen-tablet){
        display: flex;
    flex-flow:row wrap; 
    
    }


   
}

   .article-list {
        padding:30px;
         @media(min-width:@screen-tablet){
            flex-basis: 50%;
                 max-width: 50%;
         }
         &:only-of-type {
            flex-basis:100%;
            max-width:100%;
         }

         .media-body {
            padding-left:   20px;
         }
    }

.article-title {
    margin-bottom:  20px;
}