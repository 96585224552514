.search-listing {
	&__header {
		display: flex;
		flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
	}
	&__header-sort, &__header-hl {
		position: relative;
		select {
			background: none;
			border: none;
			padding: 5px 20px 10px 0;
			position: relative;
		 appearance: none;
			z-index: 2;
			&:focus {
			    outline:none;
			}
		}
		&:after {
		    content: "\f107";
		    display: block;
		    font-family: fontawesome;
		    font-weight: 900;
		    font-size: 16px;
            z-index: 1;
            position: absolute;
            top:6px;
            right:0;
 
		}
	}
}





