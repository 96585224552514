.store-row {
	display: table;
	width: 80%;
	margin: 0 auto
	
}
.store-left {
	display: table-cell
}

@media (max-width:500px) {
.store-left {
	display: block;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 30px
}
}
.store-left img {
	height: auto
}

@media (max-width:768px) {
.store-left img {
	width: 200px
}
}
.store-right {
	display: table-cell;
	vertical-align: middle;
	padding-left: 50px
}

@media (max-width:768px) {
.store-right {
	padding-left: 20px
}
}

@media (max-width:500px) {
.store-right {
	display: block;
	margin: 0 auto;
	text-align: center
}
}
.store-desc {
	margin-bottom: 30px
}
.store-desc h3 {
	 
	font-size: 30px;
	margin-bottom: 15px;
	font-weight: normal
}