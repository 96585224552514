.timeslots{
	margin:0 -6px !important;
	&__list {
		display: inline-block;
		margin:0px !important;
	}
	&__each {
		display: inline-block;
		background-color: 	white;
		border:1px solid #ccc;
		border-radius: 4px;
		padding:8px;
		color:#555;
		margin:6px;

		&:hover {
			background-color:@brand-primary;
			color:white;
		}
	}
}

.test {
	background-color: black;
}