/* feedback section */
// .zopim {
// 	@media(max-width:@screen-tablet) {
// 		display:none !important;
// 	}
// }
// .feedbac-sticky{
// 	position:fixed;
// 	bottom:0px;
// 	left:15px;
// 	z-index:100;
// 	@media(max-width:@screen-tablet) {
// 		bottom:18px;
// 		display:none !important;
// 	}
	
// 	.feedback-button {
// 		background:rgba(41, 168, 224, 0.8);
// 		color:white;
// 		display:inline-block;
// 		border-radius:6px 6px 0 0;
// 		@media(max-width:@screen-tablet) {
// 			border-radius:6px;
// 		}
// 		font-size:14px;
// 		font-weight:bold;
// 		@media(max-width:@screen-tablet) {
// 			font-size:20px;
// 		}
		
// 		i.fa {
// 			padding:8px 10px 8px 10px;
// 			background:rgba(0, 0, 0, 0.1);
// 		}
// 		>span {
// 			padding:8px 14px 8px 10px;
// 			font-size:12px;
// 			@media(max-width:@screen-tablet) {
// 				font-size:16px;
// 			}
			
// 			.md-visible {
// 				@media(max-width:@screen-tablet) {
// 					display:none; 
// 				}
// 			}
// 		}
	
		
// 	}
	
// }

// .fdbk-list {
// 	list-style:none;
// 	padding:0px;
// 	margin:0px;
	
// 	.fdbk-title {
// 		font-size:19px;
 
// 		color:@gray-dark;
		
// 		>a {
// 			color:inherit;
// 			display:inline-block;
// 			padding:10px 0;
// 		}
		
		 
		
// 	}
	
// 	.fdbk-feedback {
// 		padding: 12px;
//     background: #eee;
	
// 	.form-group {
// 		&:last-child {
// 			margin-bottom:0px;
// 		}
// 	}
// 	}
// }



.feedback-quote {
	padding:0px;
	padding-left: 	45px;
	margin:0px;
	border:none;
	font-size: 	16px;
	position: relative;
	 @media(max-width:@screen-desktop){
      margin-bottom:   20px;
    }

	&:after{
		position: absolute;
		content:'"';
		top:0;
		left:0;
		font-size:60px;
		.opacity(0.1);
		line-height: 1;
		font-weight: 	bold;
	}
}