/*global section*/
html:not(.fancybox-lock) {
  scroll-behavior: smooth;
}
 
*:hover, *:focus, a:hover, a:focus {
	text-decoration:none;
	outline:none;
}
p {
	color:@gray;
	line-height:24px;
	&:last-child {
		margin-bottom: 0px;
	}
}
table {
	// border:1px solid #e6e7e8;
	th {
		// background:#e6e7e8;
	}
}
@-moz-document url-prefix() {
  fieldset { display: table-cell; }
}

.align-right {
	.text-right;
}
body {
	overflow-x:hidden;
}
// html, body {
// 	//overflow-x:hidden;
// 	position:relative;
// 	min-height:100%;
	
// }
 
a.btn-outline {
	color:@gray-dark;
}
textarea {
	resize: vertical;
}
body {
	// overflow-x:hidden;
}
h1, h2, h3, h4, h5, h6 {
	margin-top:0px;
	line-height: normal;
}
.mb-all {
	margin-bottom:15px !important;
}
.mb {
	margin-bottom:15px !important;
}
.mb-2x {
	margin-bottom:30px !important;
}
@media (max-width:@screen-phone) {
	.mb-xs {
		margin-bottom:15px;
	}
	[class^="col-"] {
		width:100%;
		float:none;
	}
	
}

@media (max-width:@screen-tablet) {
	.mb-sm {
		margin-bottom:15px;
	}
	
	 
}
.mt-1x {
	margin-top: 15px;
}
.mt-2x {
	margin-top: 30px;
}

/*ul>li {
	margin:10px 0;
}*/
ul {
	list-style:none;
	padding:0px;
	list-style:none;
}
ul:not([class]), ol:not([class]) {
	 
	padding-left:20px; 

	margin-bottom: 0px;

	
	li {
		margin:10px 0;
	}
}
ul:not([class]) li {
	list-style-type: disc;
}
.fa-ul {
	li {
		margin:10px 0;

	}
}
.row-sm {
	margin-left:-7.5px;
	margin-right:-7.5px;
	
	[class*="col-"] {
		padding-left:7.5px;
		padding-right:7.5px;
	}
	
}

.row-xs {
	margin-left:-3px;
	margin-right:-3px;
	
	[class*="col-"] {
		padding-left:3px;
		padding-right:3px;
	}
	
}

form label, .form-label, .form-group >label {
	 font-weight:normal;
}
.form-group >label, .form-label {
	font-weight: 600;
	font-size: 14px;
	margin-bottom:12px;
}
.form-control {
	&.auto {
		width:auto;
	}
}
.color-green {color:#03B5AA !important;}
.color-black {color:#000 !important;}
.color-red {color:#FA6541 !important;}
.color-blue {color:#2a72cb !important;}
.color-purple {color:#d03370 !important;}
.color-pink {color:@brand-secondary;}
.color-white {color:#fff;}
.color-primary {color:@brand-primary;}
.color-secondary {color:@brand-secondary;}
.color-gray{color:@gray-light;}
.color-gray-imp{color:#333 !important;}
.bg-white {background-color: #fff !important;} 

.title-global{
	font-family:@headings-font-family;
	color:@gray;
	font-weight:normal;
}
.title5 {
	font-size:@font-size-h5;
	&:extend(.title-global); 
}
.title4 {
	font-size:@font-size-h4;
	&:extend(.title-global); 
}
.title3 {
	font-size:@font-size-h3;
	&:extend(.title-global); 
}
.title2 {
	font-size:@font-size-h2;
	&:extend(.title-global); 
}
.title1 {
	font-size:@font-size-h1;
	&:extend(.title-global); 
}

.gray-bg {
	background:#f1f1f2;
}
 .row-flush {
	 margin:0px !important;
	 
	 [class*="col-"] {
		 padding:0px;
	 }
 }
 
 .zero-margin-bottom {
	 margin-bottom:0px;
 }
 @media(max-width:@screen-desktop) {
	 .container {
		width: 100%;
	 }
 }
 
 
 
 .alert-info {
	 .clearfix();
	 border-radius:4px !important;
	 i.fa {
		 color:@brand-info;
		 float:left;
		 font-size:42px;
	 }
	 .alert-content {
		 margin-left:60px;
		 text-align:left;
		 font-style:italic;
		 font-size:15px;
		 
		 p {
			 margin:0px;
			 font-size:inherit;
			 
		 }
	 }
 }
 
 .validate-status {
	 padding:6px 14px;
	 font-size:12px;
	 display:block;
	 
 }
 
 .vs-success {
	 &:extend(.validate-status);
	 color:@brand-success;
	 background:lighten(@brand-success, 30%);
 }
 
  .vs-error {
	 &:extend(.validate-status);
	 color:@brand-danger;
	 background:lighten(@brand-danger, 30%);
 }
 
 .userspg {
	 @media(max-width:@screen-tablet) {
		 .page-banner, 
		 [data-toggle="offcanvas-left"], 
		 [data-toggle="offcanvas-right"], 
		 .row-offcanvas>.visible-xs,
		 .main-sidebar,
		 .user-navigation {
			 display:none !important;   
		 }
	 
 }
 }
 
.userspg {
	
		 .page-banner {
			 display:none ;   
		 }
  }
.padblock {
	padding: 15px;
}
.padblock-lg {
	padding: 25px 30px;
}
.padblock-2x {
	padding: 30px;
}
.padblock-3x {
	padding: 55px;
}

.padblock-md-large{
	padding:50px;
	@media(max-width:@screen-desktop){
		padding:30px;
	}
}
.main-content{
}

[class^="section-"] {
		padding: 20px 0; 
		@media(min-width: @screen-tablet) {
			padding: 80px 0;
		}
}

.link-hidden {
	width:100%;
	height:100%;
	position:absolute;
	content:" ";
	top:0px;
	left:0px;
	text-indent:-9999px;
}

[class*="size-"] {
		margin:0 auto;
}
    .size-xs {
		@media(min-width:@screen-phone) {
			width:@screen-phone;
		}
	}
	
	 .size-sm {
		@media(min-width:@screen-tablet) {
			width:@screen-tablet;
		}
	}
	
	.size-md {
		@media(min-width:@screen-desktop) {
			width:@screen-desktop;
		}
	}
  
  
 .main-content {
 	 padding:30px 0;
	 @media(min-width:@screen-desktop) {
	 padding:60px 0;
	 }
 }
 
 .content-block  {
	 background:#fff;
	 
	
	 
	 .content-heading {
		  padding:30px 30px 20px 30px;
		 border-bottom:1px solid #e4dfdf;
		 
		 h1, h2, h3, h4, h5, h6 {
			 // font-weight:700;
		 }
		  
	 }
	 
	 .content-body {
		padding:30px;
		&.content {
			padding:0px;
			padding-top: 15px;
		}
		  	 }
 }
 
 .panel-default {
	 border:none;
	 .panel-heading {
		 text-align:center;
		 font-weight:700;
		  border-bottom:1px solid #e4dfdf;
			 
			 h1, h2, h3, h4, h5, h6 {
				 font-weight:700;
			 }
		
		
		
		 
	 }
 }

.pad-zero {
	padding: 0px !important;
}

.notification-block {
	position: relative;
	padding-left: 45px;
	
	&:before {
		
		display: inline-block;
		font-family:"fontawesome";
		font-size: 30px;
		position: absolute;
		left: 0;
		TOP:0;
		
	}
	
	&.info {
		&:before {
		content:"";
		color:@brand-secondary;
		}
	}
	
}

.affix {
	position:fixed !important;
	top:20px;
}

.panel {
	background:white;
	.panel-heading {
		background:inherit;
	}

	&.panel-primary {
		.panel-heading {
			background:@brand-primary;
		}
	}
}

.breadcrumb {
	display: inline-block;
}

.masonry {
	@media(min-width:@screen-desktop){
	margin-left: -15px;
	margin-right: -15px;
}
}
.masonry-sizer,
.masonry-item { 
		width: 100%; 

	@media(min-width:@screen-desktop){
		width: 50%; 
		// margin-bottom: 15px;
		 .cards {
		 	margin:15px;
		 }
		 .postcard {
			 margin:15px;
		 }
	}
	
}
 
#dvMap {
	max-width: 100%;
}

 
.d-flex {
	display:flex;
}
.justify-between {
	justify-content: space-between;
}

.row-md-reverse {
	@media(min-width:@screen-desktop){
	flex-direction: row-reverse
	}
}

.row-flex-reverse {
	display:flex;
	flex-direction: row-reverse;
}
.row-column {
	flex-direction: column;
}

.col-md-reverse-max {
	@media(max-width:991px){
	flex-direction: column-reverse
	}
}

.row-flex {
	display:flex;
}
.f-direction-md-column {
	@media(min-width:@screen-desktop){
	}
}

.flex-row {
	display:flex;
	flex-direction: row;
}
.flex-column {
	display:flex;
	flex-direction: column;
}

.row-md-flex {

	@media(min-width:@screen-desktop){
		display:flex;
	}
}

.flex-row-md {
	display:flex;
	@media(min-width:@screen-desktop){
		flex-direction: row;
	}
}

.flex-column-md {
	display:flex;
	@media(min-width:@screen-desktop){
		flex-direction: column;
	}
}

.refer-friend-block {
	display:flex;
	flex-direction: column-reverse;
	@media(min-width:@screen-desktop){
		flex-direction: row-reverse;
	}
 
}