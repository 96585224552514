/* misc page */

//faq

#faq {
	.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
		border:none;
		background:none;
		color:black;
	}
	.ui-widget-content {
		border:none;
		background:none;
		padding:0px;
	}
	
	.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus,
	.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active
	 {
		border:none;
		background:none;
		padding:0px;
	}
	.ui-accordion-header-icon {
		display:none;
	}
	
	.ui-accordion-header {
		padding:6px 0;
		font-size:27px;
	}
}


.sidebar-navigation {
	@media(max-width:@screen-xs-max){
	padding-top: 15px;
	padding-bottom: 15px;
	
	
	&.affix, &.affix-bottom {
		    position: relative !important;
    top: 0 !important;
    z-index:1100;
    width: 100% !important;
    left: 0;
    right: 0;
    // background: white;
    // padding: 15px;
    bottom:auto !important;

	}
	.--user-sidebar {
		border:none;
		padding:0px;
		margin:0px;
		 padding-bottom: 15px;
	}
	}
}


// .tabs-content {

// 	display: none;
// 	&.active {
// 		display: block;
// 	}
// }

.media-thumbnail {
	// border-radius: 50%;
	overflow:hidden;
	background-color:#F2F2F2;
	 
	background-image: url('../images/no-user.svg');
	background-size: 50%;
    background-position: center center;
    background-repeat: no-repeat;
	 
	.media-object {
			object-fit: cover;
			.square(100%);
			background-color: #fff;
			 
		}
	&.flat {
		border-radius: 0px !important;
	}  
	&:not(.--wide) {
		border-radius: 50%;
		position: relative;
	}
	&.--tiny {
		.square(30px);
		
	}
	&.--small {
		.square(60px);
		 
	}
	&.--medium {
		.square(120px);
	}

	&.--wide{
		width:200px;
		height:100px;
		.media-object {
			height:100%;
			
		}
	}

}

.profile-progress-wrap {

	border-bottom: 1px solid #e4dfdf;
    padding-bottom: 15px;
    margin-bottom: 15px;
	
	strong {
		 
	}
	.btn-info {
		border-radius: 50%;
		width:20px;
		height: 20px;
		display:inline-flex;
		align-items:center;
		justify-content: center;
	}
    .progress {
    	margin-bottom: 0px;
    	// background: #fff;
    }

}


.cards {
	@p:&;
	background:white;
	position: relative;

	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0px;
	}
	&__title {
		font-weight: lighter;
		    font-size: 24px;
   			 margin-bottom: 20px; 
	}

	&__header {
		margin-bottom: 20px;
		.clearfix();
		.cards__title{
			// margin-bottom: 0px;
			@media(min-width:768px) {
			float:left;
		}
		}
	}
}

.padblock {
	padding:20px;
}
.padblock-2x {
	padding:40px;
}

.page-content {
	@media(max-width:767px){
		min-height: 1px !important;
	}
}

.header-action {
 
	// margin-top: 15px;
	// @media(min-width:768px) {
	// 	margin-top: 0px;
	// }
	input {
		margin-right: 6px;
		@media(max-width:768px) {
			margin-right: 0;
			width:100%;
			margin-bottom: 15px;
		}
	}
	select {
		display: inline-block;
		border-radius: 4px;
		// height: 31px;
		vertical-align: top;
		margin-right: 6px;
	}
	@media(min-width:768px) {
		float:right;
		display:flex;
	}
 #frmSort {
	 @media(max-width:768) {
		 margin-bottom: 15px;
	 }
 }

}


.section-contrast {
	background-color: #fff;
}



/* Style the tab content */
.tabcontent {
	display: none;
	@media(min-width:@screen-tablet){

		padding: 0 20px  ;
	}
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
    .list-block {
    	padding-left: 0px;
    	padding-right: 0px;
    }
}

/* Fade in tabs */
@-webkit-keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}


.label-outline {
	border:1px solid @brand-primary;
	border-radius: 2px;
	color:@brand-primary;
}


.icon-content {
	padding-left: 30px;
	position: relative;
	.fa {
		position: absolute;
		top:6px;
		left:4px;
	}
}

.sidebar {
    position: sticky;
    top:60px;
}


.content-wrap {
	position: relative;
}