/* homepage section */

.jumbotron {

    padding: 0px;
    margin: 0px;
    position: relative;
    z-index: 0;
    background-color: #fff;
	background-repeat: no-repeat;
	background-size:cover;
	background-position: center center;
    height: ~"calc(100vh - 75px)"; 
    // overflow-x:hidden;

	@media(min-width:@screen-tablet){
		display: flex;
	    align-items: center;
	    justify-content: center;  
	    flex-flow:column nowrap;
	    // overflow:hidden;
		
	}
    &:after {
        .overlaymask(black, 30%);
        background-color: rgba(0, 0, 0, 0.3);
        z-index: -1;
    }
    &.clinic {
		.request-wrap {
		    margin-top: 0px;
		    background-color: transparent;
        }
        &:after {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
    
    .static-content {
        position:absolute;
        z-index: 100;;
        top:0;
         
        .request-wrap {
            margin-top: 0px;
        }
    }
}

.static-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hero-content {
    text-align: center;
    color: white;
    padding: 40px 0;
    @media(min-width: @screen-tablet) {
        padding:180px 0;
    }




    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;
    }
    h1 {
        font-size: 25px;
        line-height: 35px;
        font-weight: 600;
        @media(min-width:@screen-tablet){
        font-size: 30px;

            
        }
    }
    h3 {
        font-size: 20px;
        font-weight: normal;
        line-height: normal;
    }


}

.request-wrap {
    background: rgba(0, 0, 0, 1);
    padding: 28px 0;
    position: relative;
    width: 100%;

    z-index: 2;
    display:none;
    @media(min-width: 991px) {
        // top:-100px;
        background:transparent;
        // margin-top: -100px;
        display:block;
    }

    input,
    select,
    .btn {
        border: none;
    }
    input[type="search"] {
        border-right: 1px solid #ccc;
    }
    .btn {
        border-radius: 0px;
    }

    .input-icon-box {
        @media(max-width: @screen-tablet) {
            margin-bottom: 15px;
        }

        .fa {
            color: @text-color;
            pointer-events: none;
        }
    }
}


.video-wrap {
    position: relative;
    height: ~"calc(100vh - 75px)";
    width: 100%;
    overflow: hidden;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    video {
        position: absolute;
        width: auto;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        .opacity(0.4);
        z-index: -1;

        @media(min-width: @screen-tablet) {
            width:100%;
            height: auto;
        }
    }
}

.slideshow {
    .square(100%);
    position: relative;

    .item {
        height: ~"calc(100vh - 75px)";
        width: 100%;
        position: relative;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
    .single-bg {

        background-size: cover;
        background-position: 50% 50%;

        position: absolute;
        .square(100%);
        top: 0;
        left: 0;
        .opacity(0.6);
        z-index: -1;
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
        left: 15px;
        font-size: 40px;
        color: white;
        transform: translateY(-50%);
        .opacity(0);
    }
    .owl-next {
        left: auto;
        right: 15px;
    }
    &:hover {
        .owl-prev,
        .owl-next {
            .opacity(1);
        }
    }
}

.title-section {
    margin-bottom: 50px;

    h1 {
        font-weight: 600;
    }
}

.wrap-box {
    &[data-imagebg="true"] {
        background-size: cover;
        background-repeat: none;
        background-position: 50% 50%;
    }
    &[data-bg="transparent"] {
        position: relative;
        z-index: 0;
        &:after {
            .overlaymask(black, 40%);
        }
    }
}

.introcontent {
    .wrap-box {
        height: 335px;
        position: relative;
        overflow: hidden;
        .fa {
            display: none;
            font-size: 50px;
            color: white;
        }
        &:hover {
            .overlay-content {
                min-height: 100%;

                h2 {
                    &:after {
                        width: 100%;
                        .opacity(1);
                    }
                }
            }
        }

        input[type="radio"] {
            &:checked {
               ~ .overlay-content {
                    min-height: 100%;

                    h2 {
                        align-self: flex-start;
                        font-size: 20px;
                        text-align: center;
                        &:after {
                            width: 100%;
                            .opacity(1);
                        }
                    }

                    .fa {
                        align-self: center;
                        display: block;
                    }
                }
            }
        }
    }

    .overlay-content {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        min-height: 10%;

        padding: 22px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: all ease-in-out 0.5s;

        &:before {
            bottom: 100%;
            position: absolute;
            content: " ";
            left: 20px;
        }

        h2 {
            color: white;
            font-weight: normal;
            margin: 0px;
            position: relative;
            display: block;
            width: 100%;
            transition: all ease-in-out 0.5s;
            align-self: center;
            font-size: 20px;
            line-height: normal;

            b {
                font-weight: 600;
                display: block;
            }

            &:after {
                position: absolute;
                top: 120%;
                left: 0;
                width: 0;
                height: 2px;
                background: white;
                content: " ";
                .opacity(0);
                transition: opacity 0.3s ease-in-out 0.3s, width 0.5s ease-in-out 0.3s;
            }
        }
    }

    &:nth-of-type(3n+1) {
        .overlay-content {
            background: fade(@brand-primary, 80%);
            &:before {
                .triangle(30px, @brand-primary, 80%, "up");
            }
        }
    }
    &:nth-of-type(3n+2) {
        .overlay-content {
            background: fade(@brand-secondary, 80%);
            &:before {
                .triangle(30px, @brand-secondary, 80%, "up");
            }
        }
    }
    &:nth-of-type(3n+3) {
        .overlay-content {
            background: fade(@brand-pink, 80%);
            &:before {
                .triangle(30px, @brand-pink, 80%, "up");
            }
        }
    }
}


.condition-thumbs {

    .wrap-box {
        height: 320px;

        .overlay-content {
            background-color: fade(@brand-secondary, 60%);
            position: absolute;
            bottom: 0;
            min-width: 20%;
            min-height: 10%;
            transform: translate(5px, 5px);
            color: white;
            padding: 20px 5px;
            transition: all ease-in-out 0.5s;
            @media(min-width:@screen-desktop) {
                padding: 20px 25px;
            }

            h2 {
                color: inherit;
                text-shadow: 0px 2px 3.92px rgba(0, 0, 0, 0.29);
                margin: 0px;
                font-size: 3rem;
            }
        }

        &:hover {
            .overlay-content {
                min-width: 100%;
                min-height: 100%;
            }
        }
    }
}

.condition-list {
    margin: 0px -15px;
    padding: 0px;
    list-style: none;
    font-size: 0px;

    >li {
        width: 100%;
        padding: 15px;

        @media(min-width: 600px) {
            width:calc(100% *6/12);
            display: inline-block;
        }

        @media(min-width:@screen-tablet) {
            width: calc(100% *4/12);
            display: inline-block;
        }

        @media(min-width:@screen-desktop) {
            width: calc(100% *4/12);

            &:nth-of-type(1) {
                width: calc(100% *8/12);
            }

            &:nth-of-type(7) {
                width: calc(100% *8/12);
            }
        }
    }
}


//doctors list
.doctor-block, .slider-block {
    margin: 0 auto;
    padding: 15px;
    text-align: center;

    // @media(min-width:@screen-desktop){
    // 	padding:15px;
    // }



    .doc-names, .item-title {
        margin-top: 20px;

        h4 {
            font-size: 18px;
            font-weight: 700;
            color: @gray;
        }

        .specilised {
            color: @brand-primary;
        }
    }
}

.doc-thumb {

    border-radius: 50%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    border: 3px solid #ffffff;
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.29);

    img {
        .img-responsive(); //border-radius:50% !important;
    }
 .overlay-content {
        position: absolute;
        content: ' ';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .overlaymask(@brand-primary, 50%);
        .opacity(0);
        transition: all ease-in-out 0.5s;
        // border-radius: 50%;
        z-index: 1;

        a {
            display: table;
            width: 100%;
            height: 100%;
            color: white;
            font-size: 20px;

            .fa {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

   


    &:hover {
        .overlay-content {
            .opacity(1);
        }
    }
}

    [data-hover]{
    	position: relative;

    	 .hover-content {
        position: absolute;
        content: ' ';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .overlaymask(@brand-primary, 50%);
        .opacity(0);
        transition: all ease-in-out 0.5s;
        // border-radius: 50%;
        z-index: 1;

        a {
            display: table;
            width: 100%;
            height: 100%;
            color: white;
            font-size: 20px;

            .fa {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
    	&:hover {
    		.hover-content {
	    		 .opacity(1);
	    	}
    	}
    	
    }

.owl-carousel {
	padding:0;
	@media(min-width:@screen-desktop){
		padding:0px;
	}
}
.doclists, .testimonials {
    margin-bottom: 60px;
    .owl-nav {
        .owl-prev,
        .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #C0C0C0;
            font-size: 30px;
             @media(min-width:@screen-desktop){
            font-size: 48px;
        }
        }
        .owl-prev {
left:15px;
        	 @media(min-width:@screen-desktop){
            left: -30px;
        }
        }
        .owl-next {
right:15px;

        	 @media(min-width:@screen-desktop){
            right: -30px;
        }
        }
    }

    .media{
        display:flex;
        flex-direction: column;
        align-items:center;
        text-align: center;
        justify-content: center;
        @media(min-width:@screen-tablet){
            flex-direction: row;
            align-items:center;
          
            text-align:left;
            
        }
        .media-thumbnail {
            box-shadow:0px 8px 15px rgba(0, 0, 0, 0.11);
            margin-bottom: 15px;
            padding-right:0px;
            @media(min-width:@screen-tablet){
                margin-bottom: 0px;
                
            }
        }
        .media-left {
            padding-right: 0px;
            @media(min-width:@screen-tablet){
                padding-right: 10px;
            }
        }

        .userMeta {
            @media (max-width:600px){
             display: flex;
             flex-direction: column;
             align-items: center;

            }
        }

    }

    .owl-dots {
        display:none;
        @media(min-width:@screen-tablet){
        display: flex;
        }
        justify-content: center;
        margin-bottom: 15px;
        margin-top: 15px;
        position: absolute;
        left: 0;
        right: 0;
    }
    
    .owl-dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #EEEEEE;
        margin: 5px;
    }
    
    .owl-dot.active {
        background: #0ab7c5;
    }
    
    
}

.testimonials {
    .owl-nav {
        .owl-prev, .owl-next {
            @media(max-width:@screen-desktop) {
                top: auto;
                transform: initial;
                bottom: -50px;
            }
        }
    }
}

.section-testimonial {
    background: url(../images/testimonial-bg.png) no-repeat 50% 50%;
    background-size: cover;
    padding: 70px 0;

    .title-section {
        h1, h2, h3 {
            color: white;
        }
    }
}


// Old testimonial styling (new testimonial style written on scss on the testimonials.scss on the modules)
// .testimonial-block {

//     display: table;
//     @media(min-width: @screen-tablet) {
//         width:80%;
//     }


//     margin:0 auto;

//     .client-thumb {
//         width: 200px;
//         height: 200px;
//         border-radius: 50%;
//         overflow: hidden;
//         margin: 0 auto;

//         vertical-align: middle;
//         @media(min-width: @screen-tablet) {
//             display:table-cell;
//         }


//         img {
//             .img-responsive();
//             .img-circle();
//             height:100%;
//             object-fit: cover;
//         }
//     }

//     .client-content {
//         @media(min-width: @screen-tablet) {
//             display:table-cell;
//             text-align: left;
//             padding-left: 50px;
//             margin-top: 0px;
//         }
//         vertical-align:middle;
//         margin-top:20px;
//         color:white;
//         text-align:center;

//         h3,
//         p {
//             color: inherit;
//         }
//         h3 {
//             font-weight: 600;
//         }
//     }
// }

.testimonials {
    .owl-dots {
        text-align: center;
        @media(min-width: @screen-desktop) {
            position:absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }

        .owl-dot {
            margin: 5px 0;
            @media(max-width: @screen-desktop) {
                display:inline-block;
                text-align: center;
                margin: 0 10px;
            }
            span {
                display: inline-block;
                .square(15px);
                border-radius: 50%;
                background: #cdcdcd;
            }
            &.active {
                span {
                    background: #fff;
                }
            }
        }
    }
}

.section-whyus {
    background: white;
}

.whyusblock {
    .wrapflex {
        display: flex;
        flex-direction: column;
        padding: 20px;
        color: white;
        align-items: center;
        justify-content: center;

        .fa {
            font-size: 50px;
            @media(min-width: 600px) {
                font-size:100px;
            }
        }
        em {
            color: @gray-darker;
            font-size: 24px;
        }
        strong {
            font-size: 30px;
        }
    }
    &:nth-of-type(1) {

        background: @brand-primary;
    }
    &:nth-of-type(2) {

        background: @brand-secondary;
    }
    &:nth-of-type(3) {

        background: @brand-pink;
    }
}

.rowflex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    @media(min-width: 600px) {
        flex-direction:row;
    }

    .whyusblock {
        margin: 15px;

        @media(min-width: @screen-desktop) {
            width:calc(100% *4/12);
        }
    }
}


.appointmentwrap {
    display:flex;
    flex-wrap:wrap;
    &:not(:first-child){
        justify-content: center;
    }

    
}


.introcontent {
    .wrap-box {
        .fa {
            z-index: 100;
            @media(max-width:480px){
                font-size: 20px;
            }
        }
        
        h2 {
            bottom:0;
            position: absolute;
            left: 0;
            text-align: center;
            padding:10px;
            z-index: 100;
            &:after {
                display: none;
            }
            
        }
        
        .link-radio {
            z-index: 101;
        }
        
        .overlay-content {
            height:100%;
            background:none;
            &:before {
                border-width: 15px;
                left: 50%;
                
                margin: 0 auto;
                transform:translateX(-50%);
            }
            
            &:after {
                    background: rgba(0, 175, 240, 0.8);
                    content:" ";
                    position: absolute;
                    left:0;
                    right:0;
                    bottom:0;
                    min-height: 50px;
                    transition:all 0.3s ease;
            }
        }
    
    }
}

.introcontent .wrap-box input[type="radio"]:checked ~ .overlay-content {
    &:after {
        min-height: 100%;
    }
}