/* appointments */
.appointmentwrap {
	.introcontent {
		.wrap-box {
		height:auto;
		}
		
	}
}

.doctype {
	.wrap-box {
	background:white;
	padding:35px;
	text-align:center;
	
	header {
		min-height:60px;
	}
	}
	
}

.doclists-search {
	
	.media-left, .media-body {
		padding:15px;
		transition:all ease-in-out 0.5s;
		@media(max-width:550px) {
			display:block;
			text-align:center;
			width:auto;
		}
	}
	.doc-thumb {
		.square(180px);
		transition:all ease-in-out 0.5s;
		@media(max-width:@screen-tablet) {
			.square(150px);
		}
	}
 
}

.appointment-time {
	display:none;

}

.fancypop {
	.appointment-time {
		display: block;
	}
}

.symptoms-list{
	@media(min-width:@screen-tablet) {
	column-count: 2;
	}
}

.patient-type {
	margin:-8px 0;
	padding:0px;
	list-style:none;
	margin-bottom:30px;
	
	li {
		padding:8px 0;
		
		label {
			display:block;
			margin:0px;
			
			.wrap {
				display:block;
				background:white;
				border:1px solid #c3c3c3;
				padding:15px;
				.clearfix();
				cursor:pointer;
				margin:0px;
				background:#f1f1f1;
				transition:background 0.5s ease-in-out;
				
				.fa {
					color:@brand-secondary;
					float:right;
					.opacity(0);
					transition:opacity 0.5s ease-in-out;
					font-size:20px;
				}
			}
			
			input[type="radio"] {
				display:none;
				
				&:checked {

						+ .wrap {
							background:white;
							
							.fa {
								.opacity(1);
							}
						}

					
				}
					
			}
		}
	}
}

.swMains {
	.anchor {
		margin:0px;
		list-style:none;
		padding:0px;
		font-size:0;
		float:none !important;
		display: flex;
		
		>li {
			 flex:1 0 auto;
				display:inline-block;
			vertical-align:bottom;
			float:none !important;
			padding:0px;
		 
			@media(min-width:@screen-tablet) {
				// width:calc(100%/4);
				display:inline-block;
			}
			
			a {
				font-size:16px;
				display:block;
				border:none;
				background:none;
				box-shadow:none;
				width:100%;
				height:auto;
				float:none !important;
			 	border-bottom:3px solid #c0c0c0;
				padding:20px 0;
				.opacity(0.3);
				position:relative;
				
				.stepNumber {
					background:@brand-secondary;
					.square(30px);
					border-radius:50%;
					display:inline-block;
					font-size:24px;
					font-weight:bolder;
					color:white;
					text-align:center;
					line-height:30px;
					vertical-align:middle;
					@media(max-width:@screen-desktop) {
						margin:0 auto;
						display:block;
					}
				 
				}
				
				.stepDesc {
					
					text-align:center;
					display:block;
					padding-top:10px;
					@media(min-width:@screen-desktop) {
						padding-left:12px;
						text-align:left;
						display:inline-block;
						padding-top:0px;
					}
				 
					
					small {
						font-size:14px;
						color:@gray;
						display:block;
						@media(min-width:@screen-tablet) {
							font-size:16px;
						}
						 
					}
				}
				.fa {
					position: absolute;
					left: 6px;
					top: 100%;
				 
					transform: translateY(-50%);
					display: inline-block;
					font-size: 15px;
					vertical-align: middle;
					right: 0;
					text-align: center;
					// z-index: 9999;
					color: white;
					width: 18px;
					height: 18px;
					background: #0ab7c5;
					border-radius: 50%;
					line-height: 18px;
					
					&:before {
						display:none;
					}
				}
				
				&.selected {
					border-bottom-color:@brand-secondary;
 
						.opacity(1);
						.stepDesc {
							font-weight:bold;
						}
						@media(max-width:@screen-phone) {
						.fa {
							display:none;
						}
					}
 
				}
				&.done {
					border-bottom-color:@brand-primary;
					 
 
						.opacity(1);
						
						.stepNumber {
							background-color:@brand-primary;
						}
						.fa {
							&:before {
							display:block;
							}
						}
 
				}
				&.disabled {
					border-bottom-color:#c0c0c0;
					cursor:not-allowed;
					.opacity(0.3);
					 
 
				}
			}
		}
	}
}

.actionBar {
	text-align:center;
	 border-top:1px solid #e4dfdf;
	  padding:30px;
	  margin:0 -30px;    
}

.link-radio {
	width:100%;
	height:100%;
	position:absolute;
	content:" ";
	top:0px;
	left:0px;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:20px;
	cursor:pointer;
	flex-direction:column;
	
 
	
	 
}


.appointments {
	.clearfix();
	padding:15px 0;

	&__date {
		float:left;
		font-size: 24px;
		color:@brand-primary;

		>span {
			display: block;
			font-size: 16px;
			line-height: 1;
		}
	}
	&__content {
		margin-left: 40px;
		    padding-top: 4px;
	}

	&__time {
		margin-bottom: 4px;
		white-space: nowrap;
	}

	&__status {
		display: inline-block;
		background: #ededed;
		font-size: 13px;
		border-radius: 10px;
		padding:0px 8px;
	}
}

.appointments-lists {

	.appointments {
		display: inline-block;
		vertical-align: top;
		padding:15px;
		max-width: 33.33%;
	}
}

.apmt {
	// box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);
	border:1px solid #dcdcdc;
	padding:20px;
	position: relative;
	margin-bottom: 	20px;
	background-color: 	white;
	&:last-child {
		margin-bottom: 	0px;
	}

	&__time {
		font-size: 24px;
		font-weight: bold;
		color:@brand-primary;
		line-height: 1;
		@media(max-width:@screen-phone){
			display:inline-block;
			margin-top: 4px;
			margin-bottom: 4px;
		}
	}
	
	&__type {
		display: inline-block;
		position:absolute;
		top:0px;
		left:20px;
		.fa {
			display: none;
		}
		transition:all 0.2s ease-in-out;
		// @media(min-width:@screen-phone){
		// 	right:20px;
		// 	left:auto;
		// 	top:20px;

		// 	.fa {
		// 		display: inline-block;
		// 	}
		// }
		@media(min-width:@screen-tablet){
			margin-left: 	6px;
		// font-style: italic;
		position: static ;
		margin-bottom: 	15px;

		}
		

		.status {
			font-size: 12px;
			font-weight: bold;
			color:@gray-light;

			// @media(min-width:@screen-phone){
			// 	font-size:@font-size-base;
			// 	font-weight: lighter;
			// }

		}
	}
	&__users {
		margin-top: 15px;
		position: relative;
		margin-left: 6px;
		margin-bottom: 	0px !important;
		@media(min-width:600px){
			margin-left: -8px;
		margin-right: -8px;
		}
		.user-type {
			display: none;
		}
		&--label {

			.user-type {
				display:block;
			}
			.apmt__patientblock {
				&:after{
					top:80%;
				}
			}
		}

		&:before {

			width:2px;
			height:52%;
			background-image: linear-gradient(to bottom,  #53d1ff, #0ab7c5); 
			content:" ";			 
			right:100%;
			top:50%;
			transform:translateY(-50%);
			position: absolute;
			display: inline-block;
			@media(min-width:600px){
				display: none;
			}

		}
		>li {
			
			
			margin:5px 0;
			    margin-left: 16px;
			position: relative;


			&:before {
				height:2px;
				width:18px;
				
				content:" ";			 
			right:100%;
			top:50%;
			transform:translateY(-50%);
			display: inline-block;
			position: absolute;
			@media(min-width:600px){
				display: none;
			}
			}
			@media(min-width:600px){
				margin:0px;
				padding:0 8px;
				display: inline-block;
			}

		}
		.media-thumbnail {
			display: inline-block;
			vertical-align: middle;
			margin-right: 15px;
		}

	}

	&__doctorblock {
		&:before {
			background-image: linear-gradient(to right,  #53d1ff, #0ab7c5); 
		}

	}
	&__patientblock {
		  
		position:relative;
		@media(min-width:600px){
		    margin-left: 60px !important;
		}

		&:before {
			background-image: linear-gradient(to left,  #53d1ff, #0ab7c5); 
		}
		&:after {
			@media(min-width:600px){
			height:4px;
			width:40px;
			background-image: linear-gradient(to right, #53d1ff, #0ab7c5); 
			 margin-right: 16px;
			display: inline-block;
			content:" ";
			     position: absolute;
			right:100%;
			top:50%;
			transform:translateY(-50%);
		}
		}

	}

	&__right {
		@media(min-width:@screen-tablet){
			text-align: right;
		}
	}

	&__actions {
		margin-top:8px;

	}
}


.timer {
	display: flex;
	flex-flow:row nowrap;
	 justify-content: space-between;
	 max-width: 400px;

	 &__block {
	 	text-align: center;
	 	>span {
	 		display: block;
	 		font-size: 	12px;
	 		color:@gray-light;
	 	}
	 	strong {
	 		font-weight: normal;
	 	}
	 }
}

.appntmnt-header {
	.payment-header;
	display:flex;
	flex-flow:row  wrap;
	// justify-content: space-between;
 

	.appntmnt-block {
		box-sizing: border-box;

		.media{
			display:flex;

			.media-body {
				width:auto;
			}
		}
		@media(min-width:600px){
		flex-basis: 50%;
		max-width: 50%;
	}
		@media(min-width:@screen-desktop){
		flex-basis: 35%;
		max-width: 35%;
		}
		&:nth-of-type(1){
			@media(min-width:@screen-desktop){
			padding-right: 30px;
		}
		}
		&:nth-of-type(2){
			margin-top: 20px;
			@media(min-width:@screen-desktop){
			padding-left: 30px;
			
			position: relative;
			&:before {
				position:absolute;
				right:100%;
				top:70%;
				transform:translateY(-50%);
				content:url(../images/arrows.png);
			}
			}
			@media(min-width:600px){
				margin-top: 0px;
			}
		}
		


		.media-heading {
			 white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
		}
	}

	.appntmnt-button {
		margin-top: 20px;
		@media(min-width:@screen-desktop){
			align-self: center;
		justify-self:center;
		margin-left: auto;
		margin-top: 0px;
		transition: all 0.3s ease-in-out;

		
		}
		
	}
	 
}


.list-block {
	.p-history;
	padding-left: 20px;
	padding-right: 20px;
	&:last-child{
		border-bottom: none;
	}
	&.bb {
		border-bottom:1px solid #eee !important;
	}
}

[data-status="approved"]{
	color:@brand-success;
}
[data-status="pending"]{
	color:@brand-warning;
}
[data-status="cancel"]{
	color:@brand-danger;
}

.checkbox-lists {
	@media(min-width:@screen-tablet){
	columns: 3;
	column-count: 3;	
	}
	

	.checkbox {
		margin-top: 0px;
		margin-bottom: 8px;
	}
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0ab7c5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0ab7c5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.appdetail-menu {
	position: relative;
	@media(min-width:@screen-tablet){
		position:sticky !important;
		top:0px;
	}
}

.--chatbox {
	background-color: fade(@brand-primary, 5%);
}

.map-travel {
	width:100%;
	@media(max-width:@screen-tablet){
		height: 85vh;
	}
}

#reports:checked {
	& ~ .slider ~#alerts  {
		display: none;
	}
}