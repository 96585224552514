.info-block {
  .cards;
 
  border-left:4px solid @brand-primary;

  &-img-container {
    text-align: center;
    margin-top: 20px;
    @media(min-width:@screen-desktop){
      margin-top: 0px;
      text-align:left;
      display:flex;
      justify-content: flex-end;
      align-items: center;
    }
   

    img {
      @media(min-width:@screen-desktop){
       margin-top:-12%;
       .img-responsive;
      }
    }
  }
  &-contrast-title {
    color:@green-soft;
    font-weight: bold;
    @media(min-width:@screen-desktop){
    transform: translateX(40%);
    }

  }
 
 
}
.info-card {
  &.text-center {
    img {
      display:block;
      margin:0 auto;
    }
  }

  &__title {
    font-weight: bold; 
  }
}

