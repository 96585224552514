// alert

 
.alert {
    --size:25px;
    &[class*="-"]{
        padding-left: 55px;
       
        position: relative;
        color:@text-color;
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 0px;
            + p, + div {
                margin-top: 6px;
            }
        }
        a {
			font-weight: 600;
		}

        &:before {
            font-family: "fontawesome";
            font-size: var(--size);
            position: absolute;
            top:15px;
            // top:50%;
            left:20px;
            // transform:translateY(-50%);
            // transform:translateY(-50%);
            color:inherit;
            line-height: 1;
        }
    }
    &-info {
       
        &:before {

           background-image:url("../images/info-icon.svg");
           color:@brand-info !important;
           background-size: cover;
           display: inline-block;
           width: ~"calc(var(--size) - 3px)";
           height: ~"calc(var(--size) - 3px)";

        }

        a {
            color:@brand-info;
        }
    }
    &-success {       
        &:before {
           content:"";
           color:@brand-success !important;
        }
        a {
            color:@brand-success;
        }
    }
    &-warning {       
        &:before {
           content:"";
           color:@brand-warning !important;
        }
        a {
            color:@brand-warning;
        }
    }
    &-danger{       
        &:before {
           content:"";
           color:@brand-danger !important;
        }
        a {
            color:@brand-danger;
        }
    }

    &-fixed {
        position:fixed !important;
        bottom:  var(--appbar-height) ;
        
        margin-left: 15px;
        margin-right: 15px;
        left: 0;
        right: 0;
        z-index: 8000;
        box-shadow: 2px 4px 15px 2px rgba(0, 0, 0, 0.3);

        @media(min-width:767px){
            max-width: 500px;
            bottom: 0;
            right:15px;
            left: auto;
            margin-left: 0;
        margin-right: 0;
        }
       
       
    }

    >p + p {
        margin-top: 0px;
    }

    ul {
        li {
          margin:6px 0 0 0;
        }
    }
}