.pills {
	margin:0px -6px;
	padding:0px;
	margin-bottom: 0px !important;

	&__items {
		display: inline-block;
		border-radius: 4px;
		background-color: fade(@brand-primary, 20%);
		border:1px solid @brand-primary;
		padding:6px 10px;
		line-height: 1;
		margin:6px;


	}
}