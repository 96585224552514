.not-found {
    height: auto !important;
    background: #fff;
    padding: 15px !important;
    &:after {
        display: none;
    }


    @media(min-width:@screen-tablet) {
        flex-flow: row nowrap !important;
        justify-content: space-around !important;
        padding: 50px !important;
        }

        article {

            order: 1;
            @media(min-width: @screen-tablet) {
                padding-right: 60px;
            }

            h1 {
                font-size: 22px;
                @media(min-width: @screen-tablet) {
                    font-size: 42px;
                }
            }
            .details {
                font-size: 16px;
                line-height: normal;
                color:#777;
                font-weight: lighter;
                @media(min-width: @screen-tablet) {
                    font-size: 28px;
                }
            }
        }
    


    figure {
        order: 1;
        display: none;
        @media(min-width: @screen-tablet) {
            order:2;
            display: block;
        }
        img {
            width: 100%;
            height: auto;
            @media(min-width: @screen-tablet) {
                width:300px;
            }
        }
    }
}

.no-result {
    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding:40px;
    @media(max-width:@screen-tablet){
        padding:20px;
    }

    img {
        margin:0 auto;
        display:block;
    }

    p {
        font-weight: 400;
        margin-bottom: 4px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}