/* table */




.tbl-style {
	tr >th {
	background:@brand-primary;
	color: white;
	}
	tr >td {
	background:#fff;
	}
}



.table {
	tr>td:last-child,
	tr>th:last-child{
		@media(min-width:@screen-tablet){
			text-align: right;
		}
	}
 
}

