/* audio video */
.camera-container {
	background:black;
	min-height:200px;
	margin-bottom:15px;
}

.test-holder {
	height:200px;
	background:white;
	display:table;
	width:100%;
	position:relative;
	margin-bottom:15px;
	
	.fa {
		display:table-cell;
		vertical-align:middle;
		text-align:center;
		font-size:36px;
	}
	
	.range {
		position:absolute;
		right:12px;
		top:50%;
		transform:translateY(-50%);
		
		span {
			width:20px;
			height:6px;
			display:block;
			background:@gray-lighter;
			margin:6px 0;
			
			&.active {
				background:@brand-primary;
			}
		}
	}
}