
.font-face(@family: arial, @weight: normal, @style: normal){
    @font-face{
        font-family: @family;
        src:url('../fonts/@{family}.eot');
        src:url('../fonts/@{family}.eot?#iefix') format('embedded-opentype'),
            url('../fonts/@{family}.woff') format('woff'),
            url('../fonts/@{family}.ttf') format('truetype'),
            url('../fonts/@{family}.svg#icon') format('svg');
        font-weight: @weight;
        font-style: @style;
    }
}

.font-face(Gotham-Bold, normal, normal); 
.font-face(Gotham-Light, normal, normal);    

.make-column(@large, @medium, @small, @tiny: 16) {
	.make-xs-column(@tiny);
	.make-sm-column(@small);
	.make-md-column(@medium);
	.make-lg-column(@large);
}
.make-offset(@large, @medium, @small, @tiny: 16) {
	.make-xs-column-offset(@tiny);
	.make-sm-column-offset(@small);
	.make-md-column-offset(@medium);
	.make-lg-column-offset(@large);
} 

@media (max-width:@screen-phone) {
.generate-columns(12);

.generate-columns(@n, @i: 1) when (@i =< @n) {
  .col-imp-@{i} {
    width: (@i * 100% / @n) !important;
	float:left;
	    padding-left: 15px;
    padding-right: 15px;
  }
  .generate-columns(@n, (@i + 1));
}
}

.background-size(@size){
  -webkit-background-size: @size;
     -moz-background-size: @size;
       -o-background-size: @size;
          background-size: @size;
}


.box-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
	-webkit-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
	-moz-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
	box-shadow:		@x @y @blur @spread rgba(0, 0, 0, @alpha);
}


.triangle(@width: 5px, @color, @transpancy: 100%, @arrow) when (@arrow="up"){
			  width: 0; 
	  		  height: 0; 
			  border-left: @width solid transparent;
			  border-right: @width solid transparent;
			  border-bottom: @width solid fade(@color, @transpancy);
 }
			  
.triangle(@width: 5px, @color, @transpancy: 100%, @arrow) when (@arrow="down"){
			  width: 0; 
	  		  height: 0; 
			  border-left: @width solid transparent;
			  border-right: @width solid transparent;
			  border-top: @width solid fade(@color, @transpancy);
 }
.triangle(@width: 5px, @color, @transpancy: 100%, @arrow) when (@arrow="left"){
			  width: 0; 
	  		  height: 0; 
			  border-top: @width solid transparent;
			  border-bottom: @width solid transparent;
			  border-right: @width solid fade(@color, @transpancy);
}
.triangle(@width: 5px, @color, @transpancy: 100%, @arrow) when (@arrow="right"){
			   width: 0; 
	  		  height: 0; 
			  border-top: @width solid transparent;
			  border-bottom: @width solid transparent;
			  border-left: @width solid fade(@color, @transpancy);
}



.overlaymask(@color, @opacity) {
		position:absolute;
		content:" ";
		width:100%;
		height:100%;
		background:fade(@color,@opacity);
		top:0;
		left:0;
		z-index:-1;
}

 
 //margin bottom mixin
.generate-mb(6);

.generate-mb(@n, @i: 1) when (@i =< @n) {
  .mb-@{i}x {
    margin-bottom: (@i * 15px) !important;
  }
  .generate-mb(@n, (@i + 1));
}


//margin-top mixin
.generate-mt(6);

.generate-mt(@n, @i: 1) when (@i =< @n) {
  .mt-@{i}x {
    margin-top: (@i * 15px) !important;
  }
  .generate-mt(@n, (@i + 1));
}
 
.truncate( ) {
	display:block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
 
}