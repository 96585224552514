.display-text {
	font-size: 	22px;
	font-weight: lighter;
	// margin-top: 	30px;
}

.cards__title {
	&--bold {
		font-weight: 600;
		font-size: 	28px;
	}
}

.refer-friend-image-block {
	position: static;
	display: none;

	@media(min-width: @screen-desktop){
		display: block;
	}

	>img {
		position: relative;
	 		margin-top: 	-17%;
		right:-17%;
	}

}


.social-btns {
	margin-top: 	0;
	margin-left: 	-25px;
	margin-right: 	-25px;
	>li {
		display: inline-block;
		vertical-align: top;
		text-align: center;
		margin:0 25px;
		@media(max-width:@screen-tablet){
			padding-bottom: 40px;
		}

		img {
		 
			max-width: 100%;
			display: block;
			margin: 0 auto;
		}
	}

	&__anchor {
		.square(60px);
		display: flex !important;
		align-items:center;
		justify-content: center;
		display: inline-block;
		flex-direction: column;
		position: relative;

		&:hover {
			color:@gray-dark;
		}

		&:after {
			content:attr(title);
			position: absolute;
			font-weight: bold;
			// font-size: 	22px;
			top:100%;

		}
	}

	&--facebook, &--outlook {
		// height:42px;

		>img {
			height:42px;
		}
	}

	&--facebook {
		color:#3b5998;
	}
	&--google {
		color:#ea4335;
	}
	&--yahoo {
		color:#410093;
	}
	&--outlook {
		color:#2196f3;
	}

	&--absolute-right {

		top:18px;
		right:25px;
		margin-top: 	0px;
	 
			position:absolute;

			.social-btns__anchor:after {
				display: none;
			 
			

		 
	}
			}
}


.custom-checkbox-label{
		font-weight: lighter;
		padding-left: 	30px;
		position: relative;
		cursor:pointer;
		&:before, &:after {
			display: inline-block;
			position:absolute;
			left: 0;
			border-radius: 2px;

		}
		&:before {
			.square(20px);
			border:1px solid #c9c9c9;
			background-color: 	#fbfbfb;
			content:' ';
			
		}
		&:after {
			content:'';
			font-family: 'fontawesome';
			.opacity(0);
			font-size: 12px;
		    .square(20px);
		    text-align:center;
		    // top: 0;
		    line-height: 20px;
		    transition:opacity ease-out 200ms;
		}
	}

	.custom-checkbox-input{
		display: none;
		&:checked {
			~ .custom-checkbox-label:after {
				.opacity(1);
			}
		}
	}

.checkbox-custom {
	
	
}


.avatar {
	border-radius: 50%;
		border:1px solid #cccccc;
		background-color: #fbfbfb;
		display: inline-block;
		overflow:hidden;
		vertical-align: middle;
		background-image: url(../images/no-users.svg);

		>img {
			object-fit: cover;
			height:100%;
			max-width: 100%;

			&.no-user {
				padding:9px;
			}
		}

	&__title {
		font-weight: lighter;
		margin-left: 12px;
	}
}
.contactlist-select {
	display:flex;
	flex-wrap: wrap;
 
	margin-left: -20px;
	margin-right: -20px;
	flex-direction: column;
	@media(min-width:600px){
		flex-direction: row;
		&--column {
			flex-direction: column;
		}
	}

	&--column {
		>li {
			flex-basis: 100% !important;
		max-width:100% !important;
		padding:8px 20px;
		}
	}

	>li {
	 
	 
		padding:6px 20px;

		@media(min-width:600px){
			flex-basis: 50%;
		max-width:50%;
		padding:15px 20px;

		}
		@media(min-width:@screen-desktop){
			flex-basis: 33.33%;
		max-width:33.33%;

		}
	}
	.avatar {
		.square(40px);
		position: absolute;
		left: 0px;
		top: 50%;
    transform: translateY(-50%);
			&__title {
				display: block;
				white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
			}
	}

	label {
		// display: flex;
		// align-items: center;
		padding-right: 	30px;
		padding-left: 40px;
		display: block;
		    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0px;

		&:before, &:after {
			left:auto;
			right:0;
		}

		&:after {
			top: 50%;
    transform: translateY(-50%);
		}
	}

	.custom-checkbox-input {
		&:checked {
			~ .custom-checkbox-label .avatar {
				border-color: @brand-primary;
			}
		}
	}
}


.earning-block {
	@media(min-width:@screen-phone){
	text-align: right;
	}

	.earning-label {
		font-size: 14px;
		font-weight: 300;
	}
	.priceBlock {
		@media(min-width:@screen-phone){
		justify-content: flex-end;
		}
		font-size: 18px;
		margin-top: 6px;
	}
}

.--referfriend { 
	&:not(:last-child){
		border-bottom: 1px solid #eee !important;
	}
	height: 100%;
	.email-address {
		font-size: 14px;
		margin-bottom: 0px;
		.truncate();  
		
	}
	.media-heading {
		font-weight: 600;
		.truncate();  
		line-height: normal;
		// margin-bottom: 0px;
	}

	.media-body-group {
		
		// @media(min-width:@screen-desktop){
		// 	max-width: 194px;  
		// }
	}

	.extra-meta {
		font-size: 14px;
		font-weight: 300;
		margin-top: 5px;

	}
	.status {
		font-weight: 600;
		&--approved {
			color: @green-soft;
		}
	}
}

.refer-list {
	display: flex;
    flex-wrap: wrap;
		// justify-content: center;
		>[class*="col-"] {
		 

				flex:1 1 100%;
				max-width:100%;


			 
			&:not(:last-child){
				border-bottom: 1px solid #eee !important;
			}

			.--referfriend {
				padding-left: 0;
				padding-right: 0;
			}
		 
			 
		}
}