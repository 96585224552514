// payment

.payment-header {
	background-color: 	#fbfbfb;
	padding:20px;
}

.p-summery {

	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 	0px;

	@media(max-width:@screen-desktop){
		margin-bottom: 15px;
	}
	&__list {
		display: inline-block;
		vertical-align: top;
		padding:0 15px;

		>label {
			display: block;
			font-weight: lighter;
			margin-bottom: 	0px;
		}

		&.has-received {
		.p-summery__total {
			color:@green-soft;
		}
	}
	&.has-expensed {
		.p-summery__total {
			color:@brand-danger;
		}
	}
	}

	&__total {
		font-size: 24px;
		display: block;
	}

	
}

.form-group {
	&.has-iconright {
		position: relative;
		.form-control {
			padding-right: 	30px;
		}
		.fa {
			position:absolute;
			top:50%;
			transform:translateY(-50%);
			right:12px;
			pointer-events: none;
		}
	}
}

.date-history-title {
	font-size: 	24px;
	font-weight: lighter;
	margin-bottom: 	20px;
}


.p-history{
	border-bottom: 	1px solid @gray-lighter;
	padding:18px 0;

	&__paymentmethod {
		display: inline-block;
		float:left;
		.fa {
			color:@brand-primary;
		}
	}

	&__body {
		padding-left: 	34px;
	}

	&__list {
		margin-bottom: 	15px;
		&:last-child {
			margin-bottom: 	0px;
		}
		>.row {
			margin-right: 	-6px;
			margin-left: 	-6px;
		}
		[class*="col-"]{
			padding-left: 	6px;
			padding-right: 	6px ;
		}

		&.has-received {

			.p-history__arrow {
				color:@green-soft;
			}
			.p-history__price {
				color:@green-soft;
			}
		}
		&.has-expensed {

			.p-history__arrow {
				color:@brand-danger;
				transform:rotate(180deg);
			}
			.p-history__price {
				color:@brand-danger;
			}
		}

		&.has-sent {

			.p-history__arrow {
				color:#b5b5b5;
				transform:rotate(180deg);
			}
			.p-history__price {
				color:#b5b5b5;
			}
		}
	}
	&__arrow {
		display: inline-block;
	}
	&__date {
		color:#b5b5b5;
		padding-left: 	12px;
		

	}

	&__detail {
		margin-top: 	-4px;

		p {
			&:last-child{
				margin-bottom: 	0px;
			}
		}
	}

	&__price {
		@media(min-width:@screen-tablet){
			text-align:right;
		}
	}





}


.tbl-block {
	@media(min-width:@screen-tablet){
		display: table;
		width:100%;
	}

	&__cell {
		@media(min-width:@screen-tablet){
			display: table-cell;
			vertical-align: top;
		}
		padding:25px;
		background-color: 	#fff;

		

		 

	}
	&--info {
		background-color: 	#fbfbfb;
		
	}
	&--1by3 {
		@media(min-width:@screen-tablet){
		width:33.33%;
	}
	}
	&--2by3 {
		@media(min-width:@screen-tablet){
		width:66.66%;
	}
	}
}

.inputradio {
	content:" ";
	.square(18px);
	background-color:#e8e8e8;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	position:absolute;
	top:50%;
	left: 15px;
	transform:translateY(-50%);
}
.payment-type {
 
	margin-left: -4px;  
	margin-right: -4px; 
	margin-bottom: 	30px;
	position: relative;

	>li {
		display: inline-block;
		padding:2px;


	}

	&__button {
		border:1px solid #c8c8c8;
		display: inline-flex;
		justify-content: center;
		font-weight: 400;
		font-size: 20px;
		position:relative;
		padding:15px 30px 15px 45px;
		border-radius: 2px;
		position:relative;
		cursor:pointer;

		img {
			height:30px;
			width:auto;
			+ span {
				margin-left: 6px;
			}
		}
		&:hover {
			border-color:@brand-primary;
		}

		&:before {
			&:extend(.inputradio);
			
		}

	}
	input[type="radio"]{
			display: none;
			&:checked {
				+ label {
					border-color:@brand-primary;
					&:before {
						background-color: 	@brand-primary;
					}
				}
			}
		}

	&__detail {
		display: none;
		// position: absolute;
		// top:100%;
		// padding-top: 	20px;

		
	}

	&--mobile {
		display: block;
		margin-bottom: 	0px;
		padding-bottom: 	0px;
		& + .payment-type__detail {
			display: none;
			margin-bottom: 15px;
			
		}
		
		&.active {

			li {
				padding-bottom: 	0px;
			}
			label {
				border-color:@brand-primary;
				margin-bottom: 	0px;
				&:after {
					&:extend(.inputradio);
					left: auto;
					right:15px;
					background-color: 	@brand-primary;
				}
			}
			& + .payment-type__detail {
				display: block;
				background-color: 	#f1f1f1;
			padding:15px;
			}
		}
		@media(min-width: @screen-tablet){
			display: none;
		}
		li {
			display: block;
		}
		.payment-type__button {
			padding:15px;
			display:block;
			&:before {
				display: none;

			}
			&:after {
				&:extend(.inputradio);
				left: auto;
				right:15px;
			}

		}


			input[type="radio"]{
			 
				&:checked {
					+ label {
						border-color:@brand-primary;
						&:after {
							background-color: 	@brand-primary;
						}
					}
				}
		}
	}

	&--desktop {
		display: none;
		@media(min-width: @screen-tablet){
			display: block;
		}
	}

}


.empty-label {
		display: none;
		@media(min-width:@screen-tablet){
			display: block;
	}
}


.last-row {
	td {
		font-weight: bold;
	}
}

.form-relative {
	position: relative;
}
.paymnet-icon {
	display:none;
	align-items: center;
	position: absolute;
    top: 8px;
    left: 15px;
    background: white;
    -webkit-appearance: none;
    -webkit-appearance: none;
    pointer-events: none;
	img {
		max-width: 100%;
		height: auto;
		width: 30px;
		margin-right: 8px;
	}
}

.payment-method-content {
	display:none;
}
