.user-steps {
    display:flex;
    
    &__content {
        max-width:100%;
        flex-basis:100%;
        padding:30px;
        @media(min-width: @screen-tablet){
        max-width:60%;
        flex-basis:60%;
        padding:55px;
        }
       
    }

    &__image {
        display:none;
        @media(min-width: @screen-tablet){
            display:block;
        max-width:40%;
        flex-basis:40%;
        }
        background-color: @brand-primary;
        img {
            object-fit: cover;
            width:100%;
            height:100%;
            opacity: 0.3;
        }
    }
}

.title-lined {
    color:@brand-primary;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    display:flex;
    align-items: center;
    flex:0 0 auto;
    &:after{
        display: inline-block;
        height:2px;
        // width: 100%;
        background-color: @brand-primary;
        content:" ";
        flex: 1 0 auto;
        margin-left: 20px;

    }
}
 .customOption-inline {
     margin-left:-15px;
     display: flex;
     flex-wrap: wrap;
     .customOption {
         margin-left: 15px;
         pointer-events: auto;
         opacity: 1;
         flex:1 1 auto;
         margin-bottom: 15px;
         
          
         transition:all 0.3s;
        //  &:last-child  {
        //      flex:0 1 auto;
        //  }

     }
     &:hover {
        .customOption:not(:hover){
             
            opacity: 0.4;
        }
    }
 }

 .customOption-block {
    display: flex;
    flex-direction: column;
     
 }
.customOption {
    @p: customOption;
    [type="radio"],
    [type="checkbox"] {
        display: none;
      
    }
   


}

.js-userType {
    @p: customOption;
    &.active {
        .@{p}__item {
            border-color:@brand-primary;
            transform: translateY(-5px);
            box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.20);
    
            svg {
                fill:@brand-primary;
            }
            .@{p}__label {
                color:@brand-primary;
                span {
                    width:auto;
                }
            }
          }  
       }
       
       .@{p}__item {
            border-radius: 4px;
            border:2px solid #333;
            // width:45%;
         	// @media(min-width: 1200px){
            //     width:225px;
            // }
            
            height:140px;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            pointer-events: auto;
            box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
            transform:translateY(0px);
            transition: all 0.3s;
            &:hover {
                transform:translateY(-5px);
                box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.20);
                
            }
    
        }
    
        .@{p}__label {
            color: #333;
            margin-top: 14px;
            span {
                width:0;
                white-space: nowrap;
                overflow: hidden;
                display: inline-flex;
    
                
            }
             
        }
}

.custom-fromgroup {
  
    > label {
        font-size: 22px;
        font-weight: 400;
    }
}
.customOption {
    @p: customOption;
    &--default {
        flex:0 1 auto !important;
        
        
        span {
            --size:12px;
            display:inline-block;
            border-radius: var(--size);
            width:var(--size);
            height:var(--size);
            background-color: #EEEEEE;
            margin-right: 6px;
        }

        .@{p}__item {
            display: flex;
            justify-content: center;
            align-items: center;
            border:1px solid #333;
            padding:10px 15px;
            border-radius:4px;
            cursor: pointer;
        }

        input[type="radio"],
        input[type="checkbox"]{
            &:checked {
               
                  ~ .@{p}__item {
                    span {
                        background-color:@brand-primary;
                    }
                    border-color: @brand-primary;
                    color:@brand-primary;
                }
                
            }
        }
        .@{p}__label {
            font-weight: normal;
        }

    }
}

.js-gender {
    display:none;
}

.customOption {
    @p: customOption;

    &--checkbox {
        border:1px solid #333;
        padding:10px;
        flex:0 1 auto;
    }
}
 

.form-usersetup,
.form-validate
 {
    &:invalid{
       .btn-primary {
           opacity: 0.3;
           cursor: not-allowed;
           pointer-events: none;
       }
    }
     
}