// appbar styling
.appBar {
    --appbar-height:  var(--appbar-height);
    display:flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    z-index: 500;
    width:100%;
    background:white;
    height:var(--appbar-height);
 
    box-shadow: 0 -3px 20px rgba(0,0,0, 0.1);
    @media only screen and (min-width: @grid-float-breakpoint) {
        display: none;
    }
 
    
 


    &__item {
        position: relative;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
         padding: 15px;
         flex:1 0 0;
         background:white;
         transition: all 0.2s ease-in-out;

         &:hover,  &:active,  &:focus {
             text-decoration: none;
             background: rgba(33, 33, 33, 0.05);
            &:before {
                animation: ripple .3s ease-out;
            }
              
              
         }

        
        

        &:after {
            position: absolute;
            bottom: 0;
            content:" ";
             width:100%;
             height: 0.25rem;
             transform:translateY(100%);
             background-color: #00A7EE;
             transition: all 0.2s ease-in-out;
        }

        &.active {
            background: rgba(33, 33, 33, 0.05);

            &:after {
            
            transform:translateY(0);


            }
        }

        

        &:before {
            height: 50px;
            width: 50px;
            border-radius:50%;
            background-color: #ccc;
            content:" ";
            position: absolute;
            top: 50%;
            left: 50%;
            transform:translateY(-50%) translateX(-50%) scale(0);
            z-index: -1;
          

        }

    
       

    }

    &__status{
        position: absolute;
        top: 20px;
        right: 35%;
        width: rem(6);
        height: rem(6);
        border-radius: 50%;
        background-color: green;
        // background-color:#ccc;
        content: " ";
    
}

    &__icon {
        height: 15px;
        width: 15px;
        display:block;
        color: rgba(33, 33, 33, 1);
        fill: #00A7EE;

    }

    &__text {
        font-size: 11px;
        text-align:center;
        position: relative;
        white-space: nowrap;
        @media (min-width:991px) {
        font-size: 12px;

        }
        color: rgba(33, 33, 33, 1);
        margin-top: 0.375em;
        &:hover {
            text-decoration: none;

         
        }
    }


}


.ripple {
    position: absolute;
    background: rgba(0,0,0,.15);
    border-radius: 100%;
    transform: scale(0);
    pointer-events: none;
  }
  .ripple.show {
    animation: ripple .75s ease-out;
  }
  @keyframes ripple {
    to {
        transform:translateY(-50%) translateX(-50%) scale(2);
      opacity: 0;
    }
  }
  