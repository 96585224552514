/* fancy box */
@import "variable-custom"; 
.fancybox-skin {
	border-radius:0px; 
	-webkit-border-radius:0px; 
	-moz-border-radius:0px; -moz-border-radius:0px;}
.fancypop {
 
	text-align:left; 
	margin-bottom:0px !important;
	background-color: #fff;
	/*display:none;*/
	
	}
.fancybox-skin {
	border-radius:0px !important;
	color:@text-color;
 
} 
.fancypop {
	/*display:none; */
	text-align:left; 
	margin-bottom:0px !important;
	
	@media(min-width:@screen-tablet) {
		width:600px;
	}

	&.fc-small {
		@media(min-width:@screen-tablet) {
			width:400px;
		}
	}

	&.fc-medium {
		@media(min-width:@screen-tablet) {
			width:500px;
		}
	}
	
 
	
	}
.fancypop .content-block-heading {
	text-align:left; 
	padding:20px;
	border-bottom:1px solid #e9ecef;
	padding-right:60px;
	position:relative;
	h3 {
		margin-bottom: 0px;
		font-size: 20px;
		font-weight: 700;
	}
 }
 @media screen and (max-width: 767px) {
	 .fancypop .content-block-heading {
		 padding: 20px 35px 20px 20px;
	 }
 }
.fancypop .content-block-heading h3,
.lwlb_lightbox_calendar .content-block-heading h3,
 // {float:left;}
.fancypop p {color:@text-color;}
.fancypop .content-block-heading .closebtn,
.lwlb_lightbox_calendar .content-block-heading .closebtn
 {
 
	//float:right;
	position:absolute;
	right:33px;
 	font-size:20px;
	color:#ccc;
	top:15px;
	@media (max-width:@screen-phone) {
		position:absolute;
		top:0px;
		right:0px;
		background:@gray-lighter;
		padding:8px;
		&:hover {
			background:@gray-light;
			color:white;
			i.fa {
				color:white;
			}
		}
	}
} 
.fancypop .content-block-heading .closebtn:hover,
.lwlb_lightbox_calendar .content-block-heading .closebtn:hover { 
	color:#4d8ba4;
}

.fancypop .content-block-detail, .fancypop .padblock {
	padding:20px;
}
@media screen and (max-width:767px) {
.fancypop .content-block-detail,
.fancypop .padblock {
	padding: 20px 15px;
}
}

@media(max-width:990px) {
		.fancy-fullscreen {
			width:100% !important;
			height:100% !important;
			top:0px !important;
			left:0px !important;
			
			.fancybox-inner {
				width:100% !important;
			height:100% !important;
			}
			.fancybox-skin {
				min-height:100% !important;
			}
			.fancypop {
				height: 100%;
				height: 100vh;
				min-height: -webkit-fill-available;
			}
		}
}

.fancy-fullscreen .fancypop {
	@media (min-width: 768px) and (max-width: 1200px) {
		width: 100%;
	}
}


.hell {
	background-color: white;
}

.fancybox-margin {
	// margin-right: 0px !important;
}
 
.fancybox-lock .fancybox-overlay {
	overflow-y: auto !important;
}

.alert-modal {
	.closebtn {
		position: absolute;
		z-index: 9999;
		top: -15px;
		right: -15px;

	}
	.alert {
		margin: 0 !important;

		
	}
}

.pwafancypop {   
    display: flex;
	flex-direction: column;
	overflow: hidden;
	@media screen and (max-width: 990px) {
	height:-webkit-fill-available !important; ;
	 min-height: -webkit-fill-available !important;
	}
	
    .content-block-detail {
        flex:1;
        display: flex;
		justify-content: space-between;   
		flex-direction: column;
		height: 100%;
		overflow-y:auto;

		.navbar-nav {
		 
			overflow-y:auto;
			height: 100%;
			flex-direction: column;
			flex-wrap: nowrap;

		}
		.copyright-text {
		 padding:  10px;
		}
	}
	.content-block-footer {
		.menu {
			li {
				margin:8px 0;
			}
			a {
				color:@gray-700;
			}
		}
		padding: 15px 10px;
		.widget:not(:last-child){
			margin-bottom: 15px;
		}
	}

}  

