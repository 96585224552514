/*doctor profijle */

.doc-breif {
    display: flex;
    flex-direction: row;
    margin-left: -40px;
    margin-right: -40px;
    flex-wrap: wrap;
    position: relative;
    padding: 20px;

    @media(max-width: @screen-tablet) {
        margin-left: -10px;
        margin-right: -10px;
    }



    .user-thumbnail,
    .doc-profile {
        @media(min-width: @screen-tablet) {
            border-right:3px solid #dddddd;
        }
    }

    .user-thumbnail {
        flex-basis: 30%;
        max-width: 30%;
        padding: 0 40px;
        @media(max-width: @screen-tablet) {
            padding: 0 10px;
            flex-basis: 20%;
            max-width: 20%;
        }

        .doc-thumb {

            @media(min-width: @screen-desktop) {
                .square(195px);
            }
        }
    }

    .doc-profile,
    .doc-infos {
        flex-basis: 35%;
        max-width: 35%;
        padding: 0 40px;
        @media(max-width: @screen-tablet) {
            padding: 0 10px;
            flex-basis: 80%;
            max-width: 80%;
        }
    }

    p {
        margin: 0 0 15px 0;
    }

    .doc-title {
        padding-right: 30px;
        position: relative;
        transition: all 0.5s ease-in-out;
        h3 {
            font-weight: bold;
            font-size: 24px;
        }
    }

    .doc-profile {

        .doc-address,
        .doc-hospital {

            display: none;
        }
        @media(max-width:@screen-tablet) {
            align-self: center;
            .doc-speciality {
                display: none;
            }
        }
    }

    .doc-infos {

        @media(max-width: @screen-tablet) {
            display:none;
        }
    }



    #profilemore {

        &.in {
            .doc-speciality,
            .doc-address,
            .doc-hospital {
                display: block;
            }
        }
    }
}


.morelink {
    position: absolute;
    right: 0;
    top: 0;
    align-self: stretch;
    background: @brand-secondary;
    height: 100%;
    align-items: center;
    width: 30px;
    color: white;
    display: flex;
    justify-content: center;

    &:hover,
    &:focus {
        color: white;
    }
    @media(min-width:@screen-tablet) {
        display: none;
    }
    &[aria-expanded="false"] {
        .fa-minus {
            display: none;
        }
        .fa-plus {
            display: block;
        }
    }
    &[aria-expanded="true"] {
        .fa-minus {
            display: block;
        }
        .fa-plus {
            display: none;
        }
    }
}

.label-title {
    margin-bottom: 0px !important;
    font-weight: bold;
    color: @gray;
}

//jquery ui tabs
.tab-menu {
    display: block;
    position: relative;
    background-color: #fff;
    // #gradient>.vertical(#ffffff, #f4f4f4, 0, 100%);
    
    padding: 0 !important;
    
    .owl-stage-outer {
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
        @media(max-width:600px){
            border:1px solid #ccc;
            margin:0 15px;
            }
    }
    .owl-item {
        width: 25%;
    }
    a, label {
        padding: 20px 22px;
        display: block;
        color: @gray;
        margin-bottom:0px;
        font-size:@font-size-base;
        font-weight: normal;
        cursor: pointer;

        &.active {
            background: @brand-primary;
            color:#fff;
            // font-weight: bold;
        }
    }

    .owl-stage {
        width: 7000px !important;
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        .fa {
            color: #777 !important;
        }
    }
    .owl-next {
        left: auto;
        right: 0;
    }
}

.tab-content {
    
 

    ul {
        margin-bottom: 30px;
        li {
            // margin: 15px 0;
        }
    }

    .tab-block {
        display: none;
        &.active {
            display: block;
        }
    }
}

.tab-block-header {
    .clearfix();
    margin-bottom: 15px;
    .tab-block-title {
        display: inline-block;
        margin: 0px;
        font-size: 25px;
        line-height: normal;
    }
    .pull-right {
        @media(max-width: 600px) {
            float:none !important;
        }
    }
}

.map-block {
    padding: 15px;
    background: white;
    margin-top: 15px;
}

.content-block-white {
    background: white;
}


.time-list {
    padding: 0px;
    list-style: none;
    font-size: 0;
    margin: 0px !important;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;

    >li {
        display: inline-block;
        width: calc(100%/3);
        font-size: @font-size-base;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        height: 80px;
        margin: 0!important;

        @media(min-width: @screen-tablet) {
            width:calc(100%/6);
        }

        label {

            background: @gray-lighter;
            margin: 0px;
            padding: 8px 12px;
            cursor: pointer;
            line-height: 1;
            font-weight: normal;

            color: #9e9e9e;

            display: block;
            .square(100%);
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {

                color: @text-color;
            }
        }

        input {
            display: none;

            &:disabled {
                +label {
                    .opacity(0.4);
                    cursor: not-allowed;
                }
            }
            &:checked {
                +label {
                    background: @brand-primary;
                    color: white;
                }
            }
        }
    }
}

.time-tab {
    .tab-list {
        margin: 0px !important;

        >li {
            width: 50%;
            margin: 0px !important;
            &:not(:last-child){
                border-right: 1px solid #ddd;
            }
        }
    }
    .tab-content {
        border: 0px !important;
        padding: 0px !important;

        .ui-tabs-panel {
            padding: 0px !important;
        }
    }
}

.media-counter {
   display:none;
   margin-bottom: 10px;
   @media(min-width: @screen-phone) {
     display: table-cell;
    padding-right: 15px;
   }

    span {
        font-size: 14px;

        width: 35px;
        height: 35px;
        background: #f1f1f1;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        line-height: 35px; // font-weight: bold;
    }
}

.user-media {
    &.--user-sidebar {
        border-bottom: 1px solid #e4dfdf;
        padding-bottom: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;

        .media-heading {
            @media(max-width:@screen-tablet){
            padding-right: 35px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            }
        }

        .media-body {
            overflow: visible;
            flex:1;
            width: 0;
            align-self: center;

            .cabinet  {
                margin:0px;
            }
        }
    }

    .media-body {
        padding-left: 10px;
        position: relative;
      
         
    }

    &.--media-panel {
        padding: 18px;
        border-bottom: 1px solid #e4dfdf;
        &:last-child {
            border-bottom: none;
        }
    }

    .--media-action {
        margin-top: 15px;
        @media(min-width: @screen-desktop) {
            text-align:right;
            margin-top: 0px;
        }
    }
}

.time {
    color:#ccc;
    display: block;
}

.sidebar-navigation {
    &.affix {
        width: 17%;
    }
    &.affix-bottom {
        bottom: 100px;
    }
}

.--media-action {
    .dropdown-menu {
        @media(min-width: @screen-sm) {
            left:auto !important;
            right: 0px !important;
        }
    }
}

.--media-panel {
    overflow: visible;
    .media-body {
        overflow: visible;
    }
}

#sidebarmenu {

    @media(max-width: @screen-xs-max) {
        // display: none;

        &.in {
            display: block;
        }
    }
    display: block;
}


.--pagesetting {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0px;

    .media-thumbnail {
        border-radius: 0px;
    }

    .media-body {
        @media(max-width: @screen-phone) {
            display: block;
            margin-top: 15px;
            padding-left: 0;
            width:100%;
        }
    }
    .media-left {
        position: relative;
    }

    &.--doctors {
        .media-body {
            @media(max-width: @screen-phone) {
                display: table-cell !important;
                margin-top: 0;
                padding-left: 10px;
            }
        }

        .media-counter {
            @media(max-width: @screen-phone) {
                display: none;
            }
        }
    }
}

.--doctors {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0px;
}

.pagesetting-menu {
    position:sticky !important;
    top:0;
    z-index: 500 !important;
}



.country_section {
    display:flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    &__image {
        width:16px;
        height:16px;
        margin-right: 6px;
    }

}

.cards--doctor-profile, .cards--search {
    position: relative;
    .country-section {
        display:inline-flex;
    }

    .cards__column {
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap; 
    }
    @media(max-width:767px){
    padding-bottom: 80px !important;
    }

   .userAction {
    @media(max-width:767px){
       position: absolute;
       bottom: 15px;
       left:0;
       right:0;
       text-align: center;
    }
   }
   .appointment-types {
       @media(max-width:@screen-tablet){
           display:none;
       }
   }

}

.userMeta {
    &__title {
        --width:60vw;
        @media(min-width:769px){
            --width:15vw;
        }
        display:inline-flex;
        margin-bottom: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width:var(--width);
        line-height: normal;
        
        
    }
    &__title__location {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media(min-width:769px){
        align-items:center;
        flex-direction: row;
        }

        .country_section {
            margin-top: 10px;
            @media(min-width:769px){
            margin-left: 10px;
            margin-top: 0;
            }
        }
    }

    .rating {
        margin-top: 6px;
    }
}

 
.doctorSpecification {
    &__major {
        font-size: 14px;
        font-weight: 600;
    }
    p {
        font-weight: 300;
        display: block;
        display: -webkit-box;
        box-orient: vertical; 
       /* autoprefixer: off */

-webkit-box-orient: vertical;

/* autoprefixer: on */
        -webkit-line-clamp: 2;
        overflow: hidden;
        // @media(max-width:@screen-tablet){
        //     display:none;
        // }

    }
}

.priceBlock {
    @media(min-width:@screen-phone){
    display:flex;
    align-items: center;
    }
    font-weight: 300;

    img {
        width:22px;
        height: auto;
        margin-right:10px ;
    }

    small {
        margin-left: 4px; 
    }
  
    span {
        display: flex;
        align-items: center;
        + span {
            @media(min-width:@screen-phone){
            margin-left: 10px;
            margin-top: 0;
            }
            position: relative;
            margin-top: 4px;
            &:before {
                content:"per";
                margin-right: 4px;
               
                @media(min-width:@screen-phone){
                    
                    content:"/";
                }
                
            }
        }
    }

}

.appointment-types{
    margin:0px;
    display: flex;
    padding:0px;
    margin-left: -15px;
    >li {
        display:flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        margin-left: 15px;
        img {
            height:16px;
            width:auto;
            margin-right: 8px;
            flex: 0 0 auto;
            align-self: flex-start;
            margin-top:2px;
        }
    }
}

.vital-records {
	&:not(:first-child){
		margin-top: 15px;
	}
	&__title {
		font-size: 18px;
		margin-bottom: 20px;
		margin-top: 0;
	}
	ul {
        margin-bottom: 0;
		// font-weight: bold;
	}
	.list-group-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.badge {
		font-size: 14px;
		font-weight: bold;
		padding: 5px 10px;
		border-radius: 15px;
		display: block;
		flex: 0 0 auto;
		align-self: start;

		sup {
			top:-1px;
			font-weight: 600; 
			margin-left:4px;
		}
	}
}