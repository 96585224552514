/*jquery ui calendar customization */

.ui-calendar-div {
	padding: 0px;
	border-radius: 0px;
	border: none;
	
	.ui-widget {
		padding: 0px;
		border:none;
		width: 100%;
	}
	
	.ui-datepicker-header {
		border-radius: 0px;
		position: relative;
		background-color:@brand-secondary;
		
		a {
			position: absolute;
			top:50%;
			width:25px;
			height: 20px;
			margin: 0px;
			cursor: pointer;
			
			&.ui-datepicker-prev {
				transform: translateY(-50%);
				background: url(../images/calendar-arrow-left.png) no-repeat;
				left:18px;
				&.ui-state-hover {
					border:none;
				}
			}
			
			&.ui-datepicker-next {
				transform: translateY(-50%);
				background: url(../images/calendar-arrow-right.png) no-repeat;
				right:18px;
				&.ui-state-hover {
					border:none;
				}
			}
		}
	}


	.ui-datepicker-title {
		background: @brand-secondary;
		border-radius: 0px;
		padding: 20px 0;
		border:none;
		margin: 0 !important;
		line-height: 1 !important;
		color: white;
		text-transform: uppercase;
		font-family: @font-family-base;
		font-weight: bold;
		font-size:20px;
	}
	
	.ui-datepicker-calendar {
		border: none;
		margin-bottom: 15px;
		tr {
			>th {
				  height:40px;
				  line-height:40px; 
				  font-size:21px;
				  font-family: @font-family-base;  
				  font-weight: bold;
				  background: white;
				   text-align: center;
			}
			
			>td {
				
				font-size: 21px;
				font-family: @font-family-opensans;
				height: 44px;
				width:44px;
				line-height: 1;
				text-align: center;
				padding: 3px 0;
				
				>span,
				>a {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					border: none;
					background: none;
					color: @text-color;
					z-index: 1;
						position: relative;
				}
				>span {
					// color: lighten(@text-color, 50%);
					cursor: not-allowed;
					
				}
				
				 a {
					&.ui-range {
							&:after {
							position: absolute;
							content:" ";
							width:100%;
							height: 100%;
							top:-2px;
							right: 0;
							background: #ccecfa;
								border-radius: 0px;
								z-index: -2;
							} 
	 
					}
				}
				
				&.ui-datepicker-unselectable {
					background: white !important;
					
				}
			 
				&.ui-datepicker-current-day {
					background: white !important;
					
					a {
					&.ui-state-active {
						color: white;
						
						&:before {
							background: @brand-primary;
							
							position: absolute;
							content:" ";
							top:50%;
							left:0;
							right:0;
							width: 44px;
							height: 44px;
							border-radius: 50%;
							z-index: -1;
							margin: 0 auto;
							transform:translateY(-54%);
							
							
						}
						
						&.ui-range, 
						&.ui-range:hover {
							&:after {
							position: absolute;
							content:" ";
							width:50%;
							height: 100%;
							top:-2px;
							right: 0;
							background: #ccecfa;
								border-radius: 0px;
								z-index: -2;
							}
	 
					}
				 
					}
					
					
					
				}
			}
				
				&.ui-datepicker-last-day {
					background: white !important;
					
					a {
					&.ui-state-active {
						color: white;
						
						&:before {
							background: @brand-primary;
							
							position: absolute;
							content:" ";
							top:50%;
							left:0;
							right:0;
							width: 44px;
							height: 44px;
							border-radius: 50%;
							z-index: -1;
							margin: 0 auto;
							transform:translateY(-50%);
							
							
						}
						
						&.ui-range, 
						&.ui-range:hover {
							&:after {
							position: absolute;
							content:" ";
							width:50%;
							height: 100%;
							top:0;
							left: 0;
							background: #ccecfa;
								border-radius: 0px;
								z-index: -2;
							}
	 
					}
				 
					}
					
					
					
				}
			}
				
				
		}
		
	}
		
	}
	
	.ui-datepicker-buttonpane {
		padding: 10px 15px;
		
		button {
			.btn;
			.btn-primary;
		}
	}

	 

	
	
}


/* @media(max-width:599px) {
	 .ui-datepicker {
	
		 top:50% !important;
		 left:50% !important;
		 transform:translate(-50%, -50%) !important;
		 width:90% !important;
		 
		  
	 }
	 
	 .ui-datepicker-calendar {
 
			  width:100% !important;
			  height:250px !important;
			  
			  tr td a, tr td .ui-state-default {
				   height:48px;
				  line-height:48px; 
				  font-size:16px;
			  }
		   
	 }
 }
 
 @media(min-width:600px) {
	 .ui-datepicker {
 
		 width:402px !important;
	 }
	 
	 .ui-datepicker-calendar {
 
			  width:380px !important;
			  height:330px !important;
		 	  margin:0 auto;
			  
			   tr td a, tr td .ui-state-default {
				
				   
			  }
		   
	 }
 }*/


.tbl-calendar {
	td {
		
		height:60px;
		line-height: 60px;
		padding: 0px !important;
		@media(max-width:@screen-tablet){
			padding: 10px !important;
		}
		background: white;
		color: lighten(@text-color, 50%);
		&.booking {
			
			span {
				position: relative;
				z-index: 1;
				.square(100%);
				display:block;
				color: white;
				&:before {
					background: @brand-primary;
					position: absolute;
					content:" ";
					top:50%;
					left:50%;
					right:0;
					.square(50px);
					border-radius: 50%;
					z-index: -1;
					margin: 0;
					transform:translateY(-50%) translateX(-50%);

				}

			}
		}
		
		&.booked-first,
		&.booked-last{
			
			span {
				color: white;
				 
				&:after {
							position: absolute;
							content:" ";
							width:50%;
							height:~"calc(100% - 12px)";
							top:50%;
							transform:translateY(-50%);
							background: #ccecfa;
								border-radius: 0px;
								z-index: -2;
							}
			}
			}
		&.booked-first {
			span {
				&:after{
				left:50%;
				}
				
			}
		}
		&.booked-last {
			span {
				&:after{
				right:50%;
				}
				
			}
		}
		&.booked-mid {
				span {
					color:@text-color;
					&:before {
						display: none;
					}
					&:after {
							position: absolute;
							content:" ";
							width:100%;
							height:~"calc(100% - 12px)";
							top:50%;
							transform:translateY(-50%);
							right: 0;
							background: #ccecfa;
								border-radius: 0px;
								z-index: -2;
							} 
				}
			}
		
		&.opened {
			color:@text-color;
		}
		 
	}
}


/*jquery ui tab customization */

.tabs {
	
    
	&.ui-widget.ui-widget-content {
		background: none;
		padding:0px;
		margin: 0px;
		border:none;
		margin-bottom:15px;
	}
	
	.ui-tabs-nav, .tab-list {
	background: none;
    border: none;
    border:none;
	border-radius:0;
	padding: 0;
		
		>li {
			.btn;
			.btn-default;
			padding: 0 !important;
			height: auto;
			border-radius: 0px !important;
			 
			
			&:focus, &:active {
				box-shadow: none;
			}
			&.ui-state-active {
				
				.btn-secondary;
				
			}
			
			a {
				padding: 12px 30px ;
				float:none !important;
				display: block;
			}
			
			
		}
	
	}
	.tab-content {
		padding: 0;
		border:1px solid #ccc;
		
		
	}
	.ui-tabs-panel{
			padding: 15px !important;
		
		}
	
}

// jquery ui tab customization

.ui-widget.ui-widget-content {
	// border:none;
}
.ui-tabs .ui-tabs-nav {
	margin:0px;
	padding:0px !important;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
	margin:0px;
	padding:0px;
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
	padding:0px;
}
.ui-tabs .ui-tabs-nav li {
	margin:0px;

}
.ui-tabs .ui-tabs-panel {
	padding:0px;
}

.ui-widget-header {
	background: transparent;
	border:none;
}
 .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{
 	background:transparent;
 }

.ui-widget-content {
	// background: transparent;
}


.ui-autocomplete {
	&.ui-widget-content {
		border:1px solid #c5c5c5;
		background: #fff;
    	color: #333;
	}

	.ui-menu-item-wrapper {
		    padding: 3px 1em 3px 0.4em;
		    position: relative;
	}

	.ui-state-active {
		    border: 1px solid #003eff;
		    background: #007fff;
		    font-weight: normal;
		    color: #fff;
	}
}

.ui-datepicker-header .ui-corner-all:before {
    content: "";
    font-family: fontawesome;
    font-size: 27px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
}

.ui-datepicker-header .ui-corner-all.ui-datepicker-next:before {
    transform: translateY(-50%) rotate(180deg);
    right: 0;
}

.ui-datepicker-header .ui-corner-all .ui-icon {
    display: none;
}