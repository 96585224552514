/*Pricing Table */

.price-table {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  padding:20px;
  transition:all 0.3s ease-in-out;
  overflow:hidden;
  position: relative;
  background-color:   #f9f9f9;
  &:hover, &.active {
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  &.disabled {
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);

    .btn {
      cursor: not-allowed;
      pointer-events: none;
    }
    &:after {
      position:absolute;
      width:100%;
      height:100%;
      background-color: #fff;
      opacity: 0.85;
      content:" ";
      top:0;
      left:0;
      z-index: 1;
    }
    &:hover {
      box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1)  !important;
    }

    &:before {
      content:attr(data-disabled-content);
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height: 100%;
      display:flex;
      align-items:center;
      justify-content: center;
      text-transform: capitalize;
      font-size: 30px;
      opacity:1;
      z-index: 2;
    }
  }
  @media(max-width:767px){
  	margin:20px 0;
  }
 
 .price-status {
 	position: absolute;
    top: 0;
    overflow: hidden;
    height: 130px;
    width: 143px;
    text-align: center;
    z-index: 0;
    right: 0;
    z-index: 100;
 

    >span {
    	font-size: 14px;
    color: #fff;
    background: @brand-primary;
   
        padding: 60px 15px 10px 15px;
    width: 300px;
    text-align: center;
    display: block;
    position: absolute;
  left: -32px;
    top: -32px;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: rotate(-44deg);
    -ms-transform: rotate(-44deg);
    transform: rotate(40deg);
    
    }
 }
}

.price-header {
	text-align:center;
	margin-bottom: 	20px;
	h2 {
		font-size: 20px;
		margin: 0px;
	}
}

.price {
		
	.currency {
		color:@gray-light;
		    display: inline-block;
    margin-top: 10px;
    vertical-align: top;
    font-size: 20px;
	}
	.value {
		font-size: 50px;
		color:@gray-dark;
		font-weight: bold;
	}
}

.price-body {

}

.price-feature {
	>li {
		&:nth-of-type(odd) {
			    background-color: rgba(23, 61, 80, 0.06);
		}
		padding:10px 12px;
		text-align: center;

		&.disabled {
			text-decoration: line-through;
			color:@gray-light;
			.opacity(0.5);
			b {
				font-weight: normal;
			}
		}
	}
}

.price-footer {
	text-align:center;
		margin-top: 	20px;
}