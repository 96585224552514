.seperater {
    --spacer:15px;
    --border-color:#ccc;
    --bg-color:white;
 margin:var(--spacer) 0;
border:1px solid transparent; 
border-top-color: var(--border-color);


&-or {
    span {
        border-radius: 50%;
        background: var(--bg-color);
        // width: 25px;
        // height: 25px;
        display: inline-block;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        line-height: 25px;
    }
}

&.text-left {
    text-align:left;
    padding-right:0;
    legend {
        margin-right: auto;
        padding-right: var(--spacer);
       text-align: left;

    }
}

&.text-right {
    text-align:right;
    padding-right:0;
    legend {
        padding-left: var(--spacer);
        margin-left: auto;
       text-align: right;

    }
}

&.text-center {
    text-align:right;
    padding-right:0;
    legend {
        padding:0 var(--spacer) ;
       margin:0 auto;
       text-align: center;
    }
}

legend {
    width:auto;
    max-width: auto;
    font-size:16px;
    margin:0px;
    transform:translateY(-2px);
    text-align: center;
    border:none;
}


}


// <fieldset class="seperater text-right">
//     <legend><span>View More</span></legend>
//   </fieldset>