/* footer section */

#footer {
    background: #fafafa;
    padding: 20px 0;
    .widget-section {
        padding: 30px 0;
    }
}

.widget-section {
    .menu {
        margin: 0px;
        padding: 0px;
        li {
            margin: 4px 0;
            a {
                color: #929292;
                padding: 5px 0;
                display: inline-block;
            }
            &:hover {
                a {
                    color: @brand-primary;
                }
            }
        }
    }
    h3 {
        // border-bottom: 2px solid @brand-secondary;
        font-size: 16px;
        color: @text-color;
        padding-bottom: 10px;
        font-weight: 600;
    }
    .widget {
		margin-bottom: 20px;
		 
            // @media(max-width: @screen-phone) {
            //     flex-basis:50%;
            //     max-width: 50%;
            //     &:last-child {
			// 		flex-basis: 100%;
			// 		 max-width:100%;
            //     }
            // }
         
    }
    >.row {
        @media(max-width: @screen-phone) {
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
       
    }
}

.widget-custom {
    .clearfix();
    .logo_variant {
        display: inline-block;
        vertical-align: top;
    }
    .menu-custom {
        display: inline-block;
        margin-left: 50px;
        @media(max-width: @screen-tablet) {
            margin-left:0px;
        }
    }
}

.appmenu {
    margin: -7.5px 0;
    padding: 0px;
    list-style: none;
    li {
        margin: 7.5px 0;
        a {
            border: 1px solid #929292;
            display: block;
            padding: 8px 15px;
            .clearfix();
            color: @gray-light;
            border-radius: 4px;
            transition: all ease-in-out 0.3s;
            .fa {
                font-size: 48px;
                width: 60px;
                float: left;
            }
            .appcontent {
                margin-left: 80px;
                span {
                    font-size: 16px;
                    display: block;
                }
                strong {
                    font-size: 24x;
                    font-weight: 600;
                }
            }
        }
        &:hover {
            a {
                background: @gray-light;
                color: white;
            }
        }
    }
}

.footer-bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-top: 1px solid @gray-lighter;
    padding-top: 30px;
    .social-links {
        margin: 0 -10px;
        display: inline-block;
        li {
            margin: 0 10px;
            display: inline-block;
            a {
                display: inline-block;
                padding: 6px 10px;
                color: @gray-light;
                font-size: 24px;
                &:hover {
                    color: @brand-primary;
                }
            }
        }
    }
    p {
        font-size: 16px;
        margin: 0;
    }
}

.footerpwalogo {
    display: flex;
    flex-wrap: wrap;
    .icon {
        width: 40px;
        height: 40px;
    }
}