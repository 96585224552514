/* registration */
 
 
 .sidebar-navigation {
	 .menu {
		 margin:0px;
		 padding:0px;
		 transition: all 0.3s cubic-bezier(0.89, 0.68, 0.38, 1.33);
		 @media(max-width: @screen-xs-max) {
    visibility: hidden;
	height: 0;
	max-height: 0;
	&.show {
		visibility: visible;
		height: auto !important;
		max-height: 600px;
	}
	&.collapse:not(.show){
		display: block;
	}
}
		 // transition: top 0.5s;
		 
		 li {
			 a {
				 padding:15px;
				 display:block;
				 color:@gray;
				 .fa {
					 margin-right:5px;
				 }
				 
				 &:hover, &.active {
					 background:@gray-lighter;
					 color:@brand-primary;
					 // font-weight: bold;
				 }
			 }
		 }
	 }
 }

 .block-infographics {
	 background: fade(@brand-primary, 3%);
	 display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	height: 100%;
	text-align:center;
	
	.navigation {
		padding:0px;
		list-style: none;
		display: inline-flex;
		justify-content: center;
		margin:0 -10px;
		>li {
			width:16px;
			height: 6px;
			background-color: #EBEBEB;
			margin:10px;
			border-radius: 4px;

			&.active {
				background-color: @brand-primary;
			}

			 
		}
	}
 }