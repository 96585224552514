/* faq */

#cssmenu {
	@media(max-width:@screen-xs-max){
		width:100%;
	}
}


.banner-full {
    height:40vh;
    width:100%;
    display: flex;
    flex-flow:column nowrap;
    background-color: fade(black, 10%);
    justify-content: center;
    align-items:center;
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &:after {
        position:absolute;
        background-color: fade(black, 50%);
        content:" ";
        .square(100%);
        z-index: -1;

    }

    &__title {
        color:white;
        font-size: 30px;
        margin-bottom: 20px;
    }
}

.help-form {
    width:90%;
    position: relative;
    @media(min-width:@screen-tablet){
        width:600px;

    }


}

.help_searchbox {
    padding-right: 50px;
}

.help-searchbtn {
    position: absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
    outline: none;
    border:none;
    background-color: transparent;
    padding:@padding-base-vertical @padding-base-horizontal;
    &:hover, &:focus, &:active {
        border:none;
        background-color: transparent;
        outline:none;
    }
}




 
#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: normal;
  display: block;
  position: relative;
 
  box-sizing: border-box;
}
#cssmenu {
 
  color: #ffffff;
}
#cssmenu ul ul {
  display: none;
}
#cssmenu > ul > li.active > ul {
  display: block;
}
.align-right {
  float: right;
}
#cssmenu > ul > li > a {
  padding: 16px 22px;
  cursor: pointer;
  z-index: 2;
  font-size: 16px;
  text-decoration: none;
  color: @gray;
  background: #f9f9f9;
  transition: color .25s ease-in-out;
  border-bottom: 1px solid #e1e1e1;
  // padding-left: 0px;
}
#cssmenu > ul > li > a:hover {
  color: #fff;
  background-color: @brand-primary;
  
}
#cssmenu ul > li.has-sub > a:after {
  position: absolute;
  right:10px;
  top: 19px;
  z-index: 5;
  display: block;
  height: 10px;
  width: 2px;
  background: @gray;
  content: "";

  transition: all 0.5s ease-in-out;
}
#cssmenu ul > li.has-sub > a:before {
  position: absolute;
  right: 6px;
  top: 23px;
  display: block;
  width: 10px;
  height: 2px;
  background: @gray;
  content: "";

  transition: all 0.5s ease-in-out;
}
#cssmenu ul > li.has-sub > a:hover {
    &:after, &:before {
        background-color: white;
    }
}
#cssmenu ul > li.has-sub.open > a {
    background-color: @brand-primary;
    color:white;
     &:after, &:before {
        background-color: white;
    }
}
#cssmenu ul > li.has-sub.open > a:after,
#cssmenu ul > li.has-sub.open > a:before {
  transform: rotate(45deg);
   background:#fff;
}
#cssmenu ul ul li a {
  padding: 14px 22px;
  cursor: pointer;
  z-index: 2;
  font-size: 14px;
  text-decoration: none;
  color: @gray;
  background: fade(@gray, 5%);
  transition: color .5s ease-in-out;
}
#cssmenu ul ul ul li a {
  padding-left: 32px;
}
#cssmenu ul ul li a:hover {
  color: #000;
}
#cssmenu ul ul > li.has-sub > a:after {
  top: 16px;
  right: 26px;
  background: #dddddd;
}
#cssmenu ul ul > li.has-sub > a:before {
  top: 20px;
  background: #dddddd;
}


.categoryblock {
  h1, h2, h3, h4, h5, h6 {
      margin-top: 20px;
  }
}

 
