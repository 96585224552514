 
	.doc-thumb {
		img {
			object-fit:cover;
			border-radius: none !important;
			.square(100%) !important;
		}
	}
.user-thumb {
    border-radius: 50%;
    .square(120px);
    overflow:hidden;
    border:2px solid #f9f9f9;
    
    >img {

        display: block;
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit:cover;
    }
  
   &.clinic {
    border-radius: 0px;
    background: #fff;
    box-shadow: 9px 10px 12px rgba(0, 0, 0, 0.1);
    border:2px solid #eee;
    >img {
        object-fit: contain
    }
   }

}
 
 

 .doctor-lists, .clinic-lists {
    .article-list {
        border-bottom: 1px solid #e4dfdf;
        &:last-child {
            border-bottom: none;
        }
        flex-basis: 100%;
        max-width: 100%;


        .media-left, .media-right, .media-body {
            display: block;
            text-align: center;
            width:auto;
            padding-top: 15px;
            @media(min-width:@screen-phone){
                display:table-cell;
                text-align:left;
                padding-top: 0px;
            }

            .user-thumb, .clinc-thumb {
                margin:0 auto;
                @media(min-width:@screen-phone){
                margin:0;
            }
            }


            [class*='col-']{
                text-align: center;
                @media(min-width:@screen-phone){
               text-align: left;
            }

            }
        }

        .media-body {
             @media(min-width:@screen-phone){
            width: 10000px;
        }
        }

		.media-left {
            padding-right: 0px;
             @media(min-width:@screen-phone){
            padding-right: 10px;

             }
        }
    }
}

.booknow {
    // margin-bottom: 20px;
    @media(min-width:@screen-tablet){
        float:right;
        margin-bottom: 0px;
    }
}


.media-title {
    font-weight: bold;

}

.specilization {
    // color:@brand-primary;
    font-weight: bold;
    font-size:14px;
}