.loader {
    margin: 0 auto 1em;
  

    svg {
        border-radius: 50%;
        padding: 6px;
        height: 50px !important;
        width: 50px !important;
        background-color: @brand-primary;
        opacity: 0.5;

        animation-name: loader;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        // animation-direction: reverse;


    }

    &--fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // z-index: -1;
        // display: flex;
        opacity: 0;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.8);
        transition: all 0.5s;
        display: none;

        &.active {
            opacity: 1;
            z-index: 500;
            display: flex;
        }
    }

    &--absolute {
        position: absolute;
        top:0;
        left:0px;
        width:100%;
        height:100%;
        // z-index: -1;
        display: flex;
        opacity: 0;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.8);
        transition: all 0.5s;
        flex-direction: column-reverse;
        &.active {
            opacity: 1;
            z-index: 500;
        }

    }

}

@keyframes loader {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

