//header
/* less header file updated what a lovely day */
.navbar{
	border-radius:0px ; 
	display: flex;
	margin:0px !important; 
	border-color:none;
	border:none;
	box-shadow:none;
	border-bottom:1px solid @gray-lighter;
	@media(min-width:@screen-tablet) {
		display: inherit;
	}
}

 
 
	 
	
.logo {
		display:flex;
		float:left;
		padding:0 42px 0  0;
		font-size:24px;
		font-weight:600;
		color:@gray-dark;
		height:74px;
		box-sizing:border-box;
		justify-content: center;
		align-items: center;
		 @media(max-width:@screen-tablet){
			 padding-right:15px;
	}

	>img {
		max-width: 100%;
		height: auto;
	}
	
		
		.brand-icon {
			display:inline-block;
			 @media(min-width:@screen-tablet){
			padding-right:15px;
			border-right:1px solid @gray-lighter;
			}
			
		}
		span {
			padding-left:15px;
			border-left:1px solid #ffffff;
			display:inline-block;
			@media(max-width:@screen-tablet){
				// display: none;
			}
		}
		&:hover {
			color:@gray-dark;
		}
	}
	#headersearch {
		display: none !important;
		@media(min-width:@grid-float-breakpoint) {
			display: flex  !important;
		}

	}
.search-home {
	display: none;
		float:left;
		width:70%;
		border:none;
		border-left:1px solid #eae6e6;
		background:transparent;
		line-height:74px;
		box-shadow:none;
		height:74px;
		width:auto;
		min-width:300px;
	@media(max-width:600px){
		min-width: 20px;
		width:60%;
	}
	@media(min-width:@grid-float-breakpoint) {
		display: block;
	}
		
		
		&:focus {
			box-shadow:none;
			outline:none;
			border-color:#eae6e6;
		}
		
		
	}
	#header {
		.navbar-nav {
			display: none;

			@media(min-width: @grid-float-breakpoint) {
				display: block;
			}
		}
	}
.navbar-nav {
	li {
		
		@media screen(min-width: 1200px) {
			border-left:1px solid #eae6e6;
		}
		
		&:first-child {
			border-left:none;
		}
		
		> a {
			padding-left:20px;
			padding-right:20px;
			font-weight:600;
			
		}
		
		&.menu-contrast {
			padding-top:14px;
			padding-bottom:14px; 
			display: none;
			@media(min-width:@screen-tablet) {
				display: block;
			}
			>a {
				padding:0 ;
				margin:0 20px;
				@media(min-width:991px) {
					height:auto;

				}

				@media(min-width:1200px) {
					height:46px;

				}
				
				>span {
					background-color:@brand-primary;
					color:white;
					display:inline-block;
					padding:12px 20px;
					border-radius:4px;
					position:relative;
					overflow:hidden;
					z-index:1;
					
					
					&:after {
						position:absolute;
						transform:translate(0, -50%);
						width:0;
						height:0;
						border-radius:4px;
						background-color:@brand-secondary;
						content:" ";
						margin:0 auto;
						top:50%;
						text-align:center;
						left:0;
						right:0;
						transition:all 0.5s ease-in-out;
						z-index:-1;
					}
					
					
				}
			}
			
			&:hover {
				>a {
					>span {
						&:after {
							width:100%;
							height:100%;
						}
					}
				}
			}
		}
		
		//drop down menu
		
		 
		
	}}
	
.dropdown-menu {
	padding:0px;
	border:none;
	left:0 ;
	min-width: 200px;
	
	>li {
		>a {
			padding: 9px 20px;
			line-height: normal;
			font-weight: 400 !important;
		}
	}
}	
/*------------------------------------------------------------offcanvas menu------------------------------------------*/

#offcanvas {display:none;}
@media(max-width:@grid-float-breakpoint) {
#offcanvas {
	display:block;
	position:fixed;
	width:250px;
	//transform:translateX(250px); 
	
	height:100%;
	top:0px;
	bottom:0px;
	 right:-250px;
	transition: right 0.5s cubic-bezier(1,-0.23,.57,1.15);
	z-index:9999;
	margin-right: 0;

 
	.box-sizing(border-box);
	
	.mobilenav-section {
		width:100%;
		height:100%;
		background:rgba(23, 24, 24, 0.8) ;
	 
	}
	
	.user-dtl {
		padding:15px 25px;
		 border-bottom:1px solid rgba(255, 255, 255, 0.1);
		 position:relative;
		 overflow:hidden;
		 background-color:rgba(0, 0, 0, 0.1);
		 height:80px;
		 
		.user-thumbnail {
			width:48px;
			height:48px;
			border:3px solid #c0c0c0;
			border-radius:50%;
			display:inline-block;
			vertical-align:middle;
			margin-right:15px;
			overflow: hidden;
			
			img {
				width:100%;
				height:100%;
			}
		}
		
		.user-name {
			 font-family:@font-family-opensans;
			 font-size:20px;
			 color:white;
			 font-weight:normal;
			 display:inline-block;
		}
		.userprofile-link {
			text-indent:-9999px;
			width:100%;
			height:100%;
			position:absolute;
			top:0px;
			left:0px;
		}
	}
	
	.mn-wrap {
		&.logged-in {
	 height: ~"calc(100% - 80px)";
		}
		&.none-user {
	 height: 100%;
		}
 
    overflow-x: hidden;
    overflow-y: auto;
	
	 .mn-table {
		 display:table;
		 width:100%;
		 height:100%;
	 }
 
 .mobilemenu {
	 display:table-cell;
	 vertical-align:middle;
	 width:100%;
	 height:100%;
	 
	 >ul {  
		 margin:0px;
		 padding:15px 0;
		 list-style:none;
		 border-top:1px solid rgba(255, 255, 255, 0.1);
		 &:first-child {
			 border:none;
		 }
		 
		 >li {
			 margin:12px 0;
			 padding:0;
			 position:relative;
			 @media screen (min-width: 1024px) {
				padding:0 25px;
			 }
			 &:before {
				 position:absolute;
				 content:" ";
				 top:0;
				 left:0;
				 height:100%;
				 width:0px;
				 background:@brand-primary;
				 transition:width ease-in-out 0.5s;
				 
			 }
			 a {
				 padding-top:8px;
				 padding-bottom:8px;
				 font-family:@font-family-opensans;
				 font-size:18px;
				 color:white;
				 @media screen(min-width: 1200px) {
					font-size:24px;
				 }
				 
				  &:hover {
				 
					 color:@brand-primary;
				 
			 }
			
			 }
			 
			
			 
			 &:hover {
				 &:before {
					 width:4px;
				 }
				 
				  >a {
 
	 				 color:@brand-primary;
 
			 }
				 
				 
			 }
			 
			 ul {
				 list-style:none;
				 display:none;
			 }
			 &.open {
				 ul {
					 display: block;
				 }
			 }
			
		 }
	 }
	 
 }
	}
	
 
	
	
}
    ;

@media screen and (max-width: 767px) {
	.caret {
		position: absolute;
		right: 15px;
		top: 15px
	}
}
#wrapper {
 
	  position:static;
	  transition:all 0.5s cubic-bezier(1,-0.23,.57,1.15);
}
body {
	   transition:transform 0.5s cubic-bezier(1,-0.23,.57,1.15);
  position:relative;
  right:0px;
  transition:right 0.5s cubic-bezier(1,-0.23,.57,1.15);
	&.offcanvas {
		//transform:translateX(250px);
		transition:right 0.5s cubic-bezier(1,-0.23,.57,1.15);
		//right:250px;
		position:fixed !important;
		width:100%;
		height:100%;
	 
	}
}

 


#overlay {
	width:100%;
	height:100%;
	position:fixed;
	background:rgba(0, 0, 0, 0.3);
	display:block;
	z-index:100;
	cursor:pointer;
	visibility:hidden;
	.opacity(0);
	transition:all 0.8s cubic-bezier(1,-0.23,.57,1.15);
}

.offcanvas {
	
	#offcanvas {
		right:0px;
		 
		
	}
	#overlay {
		.opacity(1);
		visibility:visible;
	}
	
	#wrapper {
		position:fixed;
		//transform:translateX(250px);
	}
	 
}


}

@media(max-width:@grid-float-breakpoint) {
	.navbar-brand {
		position:absolute;
		left:50%;
		transform:translateX(-50%); 
	}
	.navbar-toggle  {
		display: none;
		//float:left;
		background: none;
		border:none;
		margin-right:0px;
		z-index: 9999;
		&:hover, &:focus {
		background-color:none !important;
		border:none;
		
			
		}
	}
}

.navbar-default .navbar-toggle {
	background: none;
	border:none;
	&:hover, &:focus {
		background: none;
	border:none;
	}
}
body {
	transition:all ease-in-out 0.5s;
	&.offcanvas-menu {
		left:300px;
		#mobilenav {
			transform:translateX(0%);
			
			.nav-mask  {
				.opacity(0.6);
			}
			
		}
	}
}
 
 
.header-block {
	&:before, &:after {
		display: none;
	}
	display:flex;
	justify-content: space-between;
}