/*notification*/

.user-media {
	.icon-wrap {
		.square(25px);
		background:#dbdbdb;
		text-align:center;
			}

			.icon-static {
			@media(max-width:@screen-xs-max){
				display: none;
			}
		}

}

.hover-show {
	display: none;
	@media(max-width:@screen-xs-max){
		display: block;
	}
}

.notification-block {
	.media-heading {
		color:@gray;
		margin-bottom: 15px;
		a {
			color:inherit;
		}
	}

	&.--unread {
		.media-heading {
			font-weight: bold;
		}
	}
	&:hover {
		.icon-static {
			display: none;
		}
		.hover-show {
			display: block;
		}
		
	}
}

