.donation {
    max-height: 600px;

    p {
        color: white;
        font-size: 18px;
    }
}


.intro-section {
    svg {
        width:120px;
        height: auto;
        fill:@brand-primary;
        // .opacity(0.4);
        margin:0 auto;
        margin-bottom: 20px;
        display:block;
    }
    text-align: center;
}

.donationLists {
  margin:15px -15px;
.clearfix;
 .grid-sizer, .grid-item {
    float:left;
    // margin-bottom: 15px;
    @media(min-width:@screen-tablet){
        width: 50%;
     }
    @media(min-width:@screen-desktop){
        width: 33.33%;
     }
 }
.card {
   
    padding:15px;
    // margin: 15px 0;
    background:transparent;
    
   
}
    .card__detail {
        background-color: #fff;
    }

    .thumbnail {
        margin-bottom: 0px;
        border-radius:0px;
    }

   

   

}

.img-object-300 {
    max-height: 400px;
    object-fit: cover;
    object-position: top center;
    width: 100%; 

    @media(max-width:@screen-phone) {
        max-height: 200px;
    }
}
.donationStatus {
    margin-top: 15px;
    @media(max-width:@screen-phone) {
       opacity: 0;
       max-height: 0;
       margin: 0px;
    }
    strong {
        margin-right:4px;
    }

    .progress{
        height: 4px;
        margin:14px 0;
    }

    &__meta{
        display: flex;
        justify-content: space-between;
    }

    &__amount {
        display: flex;
        align-items:center;
    }
}

.thumbnail-ratio {
    padding-top: 60%;
    background-position: 0 0;
    background-size: cover;
}

.card {
    background-color: #fff;
    &__detail {
        padding:22px 15px;
        
    }

    &__title {
        line-height: normal;
    }

    &__meta {
        margin-bottom: 15px;
    }
}

.social-share {
    margin:0px;
    padding:15px;
    border-top:1px solid #e1e1e1;
    li {
        vertical-align: middle;
        // height: 24px;
        line-height: 1;
        // margin:0 10px;

        a {
            padding:4px;
            display: inline-block;
        }
    }
    svg {
        height:18px;
    }
}

.borderBox {
    border:1px solid #e1e1e1;
    padding:15px;
}

.donorList {
    @media(max-width:@screen-tablet){
        margin-top: 20px;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        margin-bottom: 15px;
    border-bottom:1px solid #e1e1e1;


    }
}

.media {
    margin-top: 0px; 
}

.flex {
    display: flex;
    &-between {
        justify-content: space-between;
    }
}

.donate-box {
    @media(max-width:@screen-phone) {
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 50;
        left: 0;
        margin-bottom: 0px !important;
        box-shadow: -2px 0 30px 2px rgba(0, 0, 0, 0.5);
    }
}

.donor-link, .donor-check {
    display: none;
    
}
@media(max-width:@screen-phone) {
.donor-link {
    text-align: center;
    display: block;
    font-weight: normal;
    padding-top: 6px;
    color:@brand-primary;
    cursor: pointer;
    margin:0px;
    &.last {
        display: none;
    }
    }
     
    input.donor-check {
        display: none;

       

        &:checked {
            ~ label {
                &.first {
                    display:none;
                }
                &.last {
                    display:block;
                }
            }

            ~ .donationStatus{
                opacity: 1;
                max-height: 100%;
            }
        }
        
    }
}

.donate-sidebar {
    @media(min-width:@screen-phone) {
        position: sticky;
        top: 50px;
    }
   

}
 