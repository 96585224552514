/*medical condition */

.aside-heading {
	font-size:24px;
	padding-bottom: 10px;
}

.aside-block {
	background: #fff;
	margin-bottom: 15px;
}
.media-list {
	
	.media{
		overflow: visible;
		padding:15px;
	}
	.media-object {
		.square(90px);
		border-radius: 50%;
		border:3px solid #ffffff;
		box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.29);
		object-fit: cover;
	}
	
	.media-left {
		padding-right: 20px;
	}
	
	.media-heading {
		color:@gray;
		font-weight: bold;
		
		a {
			color: inherit;
		}
	}
}

.container-responsive {
	@media(max-width:@screen-desktop){
		padding: 0px;
	}
}

.frame-box {
	margin: 15px 0;
}