.clinic-thumbnail {
	.square(120px);
	overflow: hidden;
	border: 2px solid #f9f9f9;
	position: relative;
	
	>img {
	    display: block;
	    max-width: 100%;
	    height: auto;
		object-fit: cover;
		position: absolute;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		left: 50%;
	}
}