/* pagination */

#pagination{
 text-align:center;
     text-align: center;
    border: 1px solid #eee;
    margin-top: 30px;
    padding:15px;
	
}
#pagination >ul {
	display:flex;
	justify-content: space-between;
	>li {
		padding:0 6px !important;
	}
	ul {
		margin-left:-6px;
		li {
			margin-left:6px;
		}
	}
	>li:not(.pg-number){
		flex:0 0 auto;
		
		
		a {
			border:1px solid #ccc;
			border-radius:4px;
		}
	}
	ul {
		display: flex;
	}
}
#pagination .pg-trigger {
	display:table-cell;
	vertical-align:top;
	width:18%;
	
}
#pagination .pg-trigger a{
	display:table-cell;
	border-right:1px solid #eaeaea;
	padding:14px 16px;
	
}
 
#pagination .ln-block  {
	text-align:right;
}
#pagination .ln-block a {
	text-align:right;
}
#pagination .ln-block a:last-child {
	border-right:none;
 
}
#pagination .ln-block a:first-child {
	border-left:1px solid #eaeaea;
 
}

#pagination .fp-block {
	text-align:left;

	
}
#pagination .paging-block {
	text-align:center;
	display:table-cell;
	vertical-align:top;
	width:82%;
}

#pagination  ul{
	text-align:center;
	margin:0px;
	padding:0px;
	list-style:none;
}
#pagination ul li {
	margin:0px;
	padding:0px;
	list-style:none;
	display:inline-block;
}
#pagination   ul li a{
		padding:6px 10px;
		display:block;
		line-height: 1;
		height: 30px; 
		
}
#pagination >ul >li.pg-left,
#pagination >ul >li.pg-right   {

	@media(max-width:@screen-phone){
		display:none;
	}
	 span  {
		 margin:0 6px;
	 }
	@media(max-width:@screen-tablet){
		 span {
			 display: none;
		 }
		 i.fa {
			 display: block;
		 }
	}
}
 
 
#pagination   ul li a:hover, 
#pagination ul li.active a,
#pagination .pg-trigger a:hover {
	background:fade(@brand-primary ,90%);
	color:white;
	border-radius: 4px;
}
#pagination a {
	color:@gray;
}


.referral_pagination {

	.pagination {
		>li {
			&:first-child {
				span {
					border: 1px solid #ccc;
					&:after {
						content: " First";
						display: inline-block;
						margin-left: 8px;
					}
				}
			}
			&:last-child {
				a {
					border: 1px solid #ccc;
					&:before {
						content: "Last";
						display: inline-block;
						margin-right: 8px;
					}
				}
			}
			a, span {
				margin: 0 5px;
				border-radius: 4px;
				border: none;
				font-size: 15px;
				padding: 6px 12px;
				color:@text-color;
			}
			&.active {
				span {
					color: white;
				}
			}
			&:hover {
				a {
					background-color: @brand-primary;
					color: white;	
				}
			}
		}
	}
 
 
}
