.colorCard {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  height: 200px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.colorCard__title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  line-height: 1.5;
}

@media (min-width: 992px) {
  .colorCard__title {
    font-size: 24px;
  }
}

.colorCard__number {
  color: #fff;
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  z-index: 1;
}

@media (min-width: 992px) {
  .colorCard__number {
    font-size: 52px;
  }
}

.colorCard__number-subline {
  font-size: 22px;
}

@media (min-width: 992px) {
  .colorCard__number-subline {
    font-size: 48px;
  }
}

.colorCard__button {
  color: #343a40;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
}

.colorCard__button:hover {
  text-decoration: none;
}

.colorCard__icon {
  position: absolute;
  right: -15px;
  bottom: -15px;
  width: 140px;
  margin: 0 0 -6px;
  z-index: 0;
}

.colorCard__icon svg {
  opacity: 0.7;
  width: 125px;
  height: auto;
}

.colorCard-green {
  background-image: linear-gradient(120deg, rgba(0, 175, 240, 0.7) 0%, rgba(11, 215, 217, 0.7) 100%);
  background-repeat: repeat-x;
  box-shadow: 0px 11px 21.6px 2.4px rgba(84, 227, 228, 0.3);
}

.colorCard-pink {
  background-image: linear-gradient(120deg, rgba(239, 70, 80, 0.7) 0%, rgba(244, 144, 109, 0.7) 100%);
  background-repeat: repeat-x;
  box-shadow: 0px 11px 21.6px 2.4px rgba(246, 157, 145, 0.3);
}

.colorCard-blue {
  background-image: linear-gradient(120deg, rgba(130, 70, 239, 0.7) 0%, rgba(112, 109, 244, 0.7) 100%);
  background-repeat: repeat-x;
  box-shadow: 0px 11px 21.6px 2.4px rgba(167, 125, 244, 0.3);
}

.colorCard-purple {
  background-image: linear-gradient(120deg, rgba(239, 70, 177, 0.7) 0%, rgba(236, 109, 244, 0.7) 100%);
  background-repeat: repeat-x;
  box-shadow: 0px 11px 21.6px 2.4px rgba(243, 131, 211, 0.3);
}

.colorCard-orange {
  background-image: linear-gradient(120deg, rgba(244, 141, 109, 0.7) 0%, rgba(239, 177, 70, 0.7) 100%);
  background-repeat: repeat-x;
  box-shadow: 0px 11px 21.6px 2.4px rgba(247, 175, 153, 0.3);
}

.whiteCard {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
}

.whiteCard__title {
  border-bottom: 1px solid #ced4da;
  color: #212529;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 15px;
  margin-bottom: 15px;
}

.whiteCard__cont {
  padding: 20px;
}

.whiteCard__cont .profilebar {
  min-width: 100%;
}

.whiteCard__cont .profile-complete {
  color: #0bd7d9;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .colorCard-purple .colorCard__number {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .colorCard-green {
    height: 160px;
  }
}

.cardwrap {
  padding: 20px 0;
}