
/****** Style Star Rating Widget *****/
@ratingcolor:#FFD700;

.rating{
  position: relative;
  float:left;
    border: none;
  // float: left;
  direction: rtl;
  text-align: left;
  font-size:  0;
  &[data-section]{
    &:after {
      display: inline-block;
      content:attr(data-section);
      float: right;
    margin-left: 15px;
    margin-top: 6px;
    font-size: 14px;
    }
    
  }
}

.rating { 


  &--show {
    direction: ltr;
    font-size:  14px;
    float:none ;
  	>span {
      color: #ddd; 
      margin:0 2px;
  		&.checked {
  			color: @ratingcolor; 
  		}
  	}
  }

  &--group {
    float:left;
    font-size:  0;
   

    span.fa {
      font-size:  14px;
      line-height: 25px;
      &:before {
         margin:2px 5px;
  font-size: 1.25em;
      }


    }

     &[data-section]{
        &:after{
           margin-top:   2px;
        }
       
      }

  }
}

.rating > input { display: none; } 
.rating > label:before { 
  margin:2px 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

 

.rating > label { 
  color: #ddd; 
  font-size:  14px;
  margin:0px;
 // float: right; 
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { color: @ratingcolor;  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: #FFED85;  } 


 // giving rating star:
// <fieldset class="rating">
//     <input type="radio" id="star5" name="rating" value="5" /><label class = "full" for="star5" title="Awesome - 5 stars"></label>
    
//     <input type="radio" id="star4" name="rating" value="4" /><label class = "full" for="star4" title="Pretty good - 4 stars"></label>
    
//     <input type="radio" id="star3" name="rating" value="3" /><label class = "full" for="star3" title="Meh - 3 stars"></label>
     
//     <input type="radio" id="star2" name="rating" value="2" /><label class = "full" for="star2" title="Kinda bad - 2 stars"></label>
    
//     <input type="radio" id="star1" name="rating" value="1" /><label class = "full" for="star1" title="Sucks big time - 1 star"></label>
   
// </fieldset>



//showing rating star:
// <div class="rating rating--show">
// <span class="fa fa-star checked"></span>
// <span class="fa fa-star checked"></span>
// <span class="fa fa-star checked"></span>
// <span class="fa fa-star"></span>
// <span class="fa fa-star"></span>
// </div>


