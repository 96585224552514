/*dashboard*/


// clinic dashboard

.page-header {
	margin-top: 0px;
	border:none;

	.page-title {
		// font-weight: bold;
	}
}

.dashboard-filter {

	@media(min-width:@screen-tablet){
	float:right;
	margin:0 -7px !important;
	}

	>li {
		float:left;
		margin:0 7px !important;
		border:none !important;
		border-radius:4px;


		&.ui-tabs-active {
			>a {
				background-color:@brand-primary;
			}
		}

		>a {
			display: inline-block;
			float:none;
			padding:8px 12px !important;
			background: #ccc;
			color:white !important;
			border-radius:4px;
		}
	}
}


.counter-list {
	margin-top: 15px;

	>li {
		@media(max-width:@screen-phone){
			margin:7.5px 0  ;
		}
	}
	&__block {
		display: flex;
		align-items: center;
		justify-content: center;
		background:@brand-primary;
		color:white;
		flex-direction: column;
		padding:30px 15px;
		height:120px;

		strong, p {
			color:inherit;
			margin:0px;
			text-align: center;
			line-height: 1;
		}

		strong {
			font-size: 30px;
			margin-bottom: 10px;
			line-height: 1;
			
		}
		p {
			line-height: normal;
		}
	}
}


.templatelists {
 
	&__block {
	
   background-color: #f9f9f9;
     box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
		input[type="radio"]{
				display: none;
				&:checked {
					+ .templatelists__thumbnail {
						 figcaption {
							.opacity(1);
							transform:scaleY(1);
							span {
								.opacity(1);
							}
						}

						+ .templatelists__actions {
						 	.--activate {
						 		display: none;
						 	}
						 	.--selected {
						 		display: block;
						 	}
					}
				}
					}

			}

      &:hover {
   	figcaption{
   		transform:scaleY(1);
   		span {
   			.opacity(1);
   		}
   	}
   
   }
	}
	&__thumbnail {
   position: relative;
   width: 100%;
   padding-top: 100%; /* 3:2 Aspect Ratio */
   background-position: 0 0;
   background-repeat: no-repeat;
   background-size: cover;
   

   figcaption {
   	position:absolute;
   	bottom:0;
   	left:0;
   	right:0;
   	background-color:fade(@brand-primary, 80%);
   	text-align: center;
   	padding:10px;
   	color: white;
   	.square(100%);
   	transform:scaleY(0);
   	transition: transform 0.5s ease-in-out;
   	display:flex;
   	align-items:center;
   	justify-content: center;

   		span {
   			.opacity(0);
   			 	transition: opacity 0.5s ease-in-out 0.3s;
   		}
   }


	}

	&__actions {
		font-size: 0px;
		margin:0px !important;

		 
		li {
			display: inline-block;
			font-size:16px;
			width:50%;
			padding:10px !important;
			margin:0px !important;
			&:first-child {
				border-right:1px solid #ccc;
			}
			label, a {
				display: block;
				cursor: pointer;
				color:@gray-dark;
				font-weight: bold;
				text-align: center;
				&:hover {
					color:@brand-primary;
				}
			}

			.--selected {
				display: none;
				color:@brand-primary;
			}

			 
	}
}
}
.check-status {
	.square(20px);
	background-color:@brand-primary;
	border-radius: 50%;
	line-height: 20px;
	color: white;
	text-align: center;
	position: absolute;
	top: 2px;
    font-size: 12px;
    right: -2px;
}

.remove-doctors {
	display: none;
}

.is-added-doctor {
	.add-doctors {
		display: none;
	}
	.remove-doctors{
		display: inline-block;
	}
}

//clinic doctor
.join-date {
	font-size: 14px;
	color:@gray-light;
}

.doc-highlights {
	>li {
		border-top:1px solid #dddddd;
		padding:9px 0;
		color:@gray-light;
		font-size: 14px;
		&:first-child {
			border-top:none;
			padding-top:0px;
		}

		.value {
			color:@gray-dark;
			font-size: 16px;
			margin-right: 12px;
		}
	}
	
}

.status {
	color:@gray-light;
	font-weight: lighter;
	span {
		font-weight: bold;
		color:@green;
	}

	&.pending {
		span {
			color:inherit;
		}
	}
}



.wrapflex {
	.fa {
		color:white;
	}
}

.btn-social {
	.fa {
		color:white;
	}
}
.lastlogin {
	color: #aeaeae;
	margin-top: 10px;
}