// message

.media--message {
	// box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	padding:20px;
	background-color: darken(white, 1%);
	border: 1px solid #e4dfdf;

	.media-header {
		.media-heading {
		display: inline-block;
		margin-right: 10px;

		}
		span {
			display: inline-block;
			color:@gray-light;

		}

	}
	.message-subject {
		 margin-right: 15px;
	}
	.date {
		display: inline-block;
		
		background-color:fade(@brand-primary, 80%);
		border-radius: 4px;
		font-size: 12px;
		color: white;
		padding:2px 8px;
	}

	.details {
		padding-top: 15px;
		display: none;
	}

	.reply-quote {
		background-color: #f8f8f8;
		padding:20px;
		border:1px solid #dcdcdc;
		margin-top: 25px;
	}
	.reply-form {
		border-top:1px solid #dcdcdc;
		margin-top: 30px;
		padding-top: 15px;
	}
	.subject-wrapper {
		margin-top: 8px;
	}
	.media-left {
		position: relative;

		.check-status {
			display: none;
			.square(10px);
			top:6px;
		}
	}
	&[data-msg="unread"]{
		background-color: white;
		.check-status {
			display: inline-block;
		}
	}
@media(max-width:600px){
	.media-left, .media-right, .media-body{
		
			display: block;
		}

	.media-left {
		position:absolute;
	}
	.media-header{
		padding-left: 60px;
	}
	.btn-group   {
		margin-top: 8px;
	}
	.media-body {
		width:100%;
	}

	}
}