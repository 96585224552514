
:root {

        --pwasize:1024px;
        
        
        --appbar-height:80px;
    }
@pwasize:1024px;

.pwa {
 
    padding:0px;
    padding-bottom: var(--appbar-height);
    @media(min-width:991px) {
        padding-bottom: 0px;
    }

    #footer {
        display: none;
        @media(min-width:991px) {
            display: block;
        }

    }
} 

 
.fancypop .navbar-nav {
    margin: 0;
}

.pwafancypop .navbar-nav li > a {
    align-items: center;
    color: #343a40;
    display: flex;
    font-weight: 400;
    padding: 10px;
}

.pwafancypop .navbar-nav li {
    padding: 0;
    margin: 0;
    border-left: none;
}
.fancypop .navbar-nav svg {
    --svgSize: 16px;
    width: var(--svgSize);
    height: var(--svgSize);
    vertical-align: middle;
    margin-right: 10px;
    fill: #00A7EE;
}
// @media only screen and (min-width: 768px) {
// .navbar-nav svg {
//     display: none;
// }
// }
.pwafancypop {
    #headersearch {
        .form-group {
            margin-bottom: 0;
        }
    }
    #mobilesearch {
        display: flex;
        width: 100%;
        position: relative;
    }

    #mobilesearch .form-group {
        width: 100%;
        margin: 0;
    }

    .searchicon {
        align-items: center;
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        background: #0ab7c5;
        padding: 5px 10px;
        height: 100%;
        cursor: pointer;
    }

    .searchicon svg {
        width: 20px;
        fill: #fff;
    }
}
.fancypop .closebtn svg {
    width: 15px;
    fill: #343a40;
}

.fancypop .closebtn {
    background: none !important;
    top: 13px !important;
    right: 15px !important;
}
@media only screen and (max-width: 768px) {
    .fancypop .closebtn {
        right: 5px !important;
        top: 5px !important;
    }
}
.fancypop .fancypop__head,
.fancypop .content-block-heading h3 {
    font-size: 20px;
    font-weight: 400;
}
.fancypop .content-block-heading {
    padding: 15px;
}
.appBar-sticky {
    background: white;
    border: 1px solid #aeaeae;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 1;
    z-index: 9999;
    transition: all 0.3s ease 0s;
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 14px;
    }
    &__logo {
        span {
            font-size: 20px;
            margin-left: 10px;
        }
    }
    svg {
        width: 12px;
    }
    &__bottom {
        padding: 0 10px 10px 10px;
        display: flex;
        justify-content: flex-end;
        .btn {
            padding: 5px 10px;
            font-size: 14px;
        }
    }
    &.remove {
        bottom: -100%;
        opacity: 0;
        transition: all 0.3s ease 0s;
    }
}

@media only screen and (min-width: 768px) {
    .appBar-sticky {
        display: none;
    }
    .pwa>.wrapper {
        // padding-bottom: 70px;
    }
}

.pac-container {
    z-index:8050;
}