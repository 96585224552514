.cards--media {
    padding: 30px 40px;
    display: flex;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);

    &[data-hover] {
        transform: translateY(0);
        transition: all 0.3s;

        &:hover {
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
            transform: translateY(-4px);
        }

    }

    &:not(:last-child) {
        margin-bottom: 22px;
    }

    @media(max-width:@screen-tablet) {
        padding: 30px;
    }

    svg {
        width: 16px;
        height: 16px;
    }

    .user-thumb {
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);

        @media(max-width: @screen-tablet) {
            width: 46px;
            height: 46px;
        }

    }

    &__content {

        flex: 1 1 auto;

        padding-left: 40px;
        flex-wrap: wrap;

        @media(max-width: @screen-tablet) {
            padding-left: 15px;
            // flex: 1 0 auto;
        }
    }
}

.card {
    background-color: #fff;
    &:not(:last-child){
        margin-bottom: 22px;
    }
    &--shadow {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    }
    &__header {
        display:flex;
        justify-content: space-between;
        // margin-bottom: 15px;
        &[data-toggle]{
            + .card__content.collapsing *{
                opacity: 0;
            }
            .fa {
                transform:rotate(90deg);
                transition:all 0.3s;
            }

        }
        &[aria-expanded="true"] {
            .fa {
                transform:rotate(0deg);
            }
        }
    }
    &__title {
        font-weight: 400;
        margin-bottom: 0px;
    }
    &__content {
        margin-top: 15px;
    }
}