.offline-fees {
	display:none;
}

// .offline-appointment-input {
//     &:checked {
//         +.test {
//             opacity: 0.1;
//         }
//         & ~ .offline-fees {
//             transform:translateY(0);
//             opacity: 1;
//             visibility: visible;
//             max-height: 100%;
//             overflow: visible;

//         }
//     }
// }