/* form styling */

.input-icon-box {
	position: relative;
	input {
		padding-right:30px;
	}
	.fa {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0.5; 
	}
}
@media screen and (max-width: 767px) {
	.multiboxes .input-icon-box {
		margin-top: 20px
	}
}

.ad-select {
	appearance: none;

}

.radioBox {
	label {
		border: 1px solid #ccc;
		transition: all 0.3s ease-in-out;
		padding: 10px 20px;
		border-radius: 4px;
		font-weight: normal;
		cursor: pointer;
		background-color: #fff;

		&:hover {
			border-color: @brand-primary;
		}
	}

	input {
		display: none;

		&:checked~label {
			border-color: @brand-primary;
			background-color: @brand-primary;
			color: white;

		}
	}
}

 
.tokenfield.form-control {
height: auto;
}
textarea.form-control {
	max-width: 100%;
}

.custom-option {
	@p:custom-option;
	flex-wrap: wrap;
	&--inline {
		margin-left: -15px;
		display:flex;
		.@{p}__item {
			 margin:4px 0 4px 15px;
		}
	}
	&--block {
		flex-direction: column;
	align-items: flex-start;
	display: flex;
		.@{p}__item {
			 display: block;
			 &:not(:last-child){
				 margin-bottom: 10px;
			 }
		}
		.@{p}__label {
			display:inline-block;
		}
	}
	&__item {
		--activeColor: #03B5AA;
		margin-bottom: 0px;
		font-weight: normal;
		
	}

	&__label {
		display: block;
		position: relative;
		padding-left: 30px;
		cursor: pointer;
		font-size:@font-size-base;

		&:before,
		&:after {
			content: " ";
			position: absolute;
			width: 20px;
			height: 20px;
			left: 0;
			border-radius: 50%;
			top: 0;
		}

		&:before {
			background-color: #EEEEEE;
			transition: all 0.3s;

		}

		&:after {
			display: flex;
			align-items: center;
			justify-content: center;
			content: "\f00c";
			font-family: fontawesome;
			font-size: 10px;
			opacity: 0;
			transition: all 0.3s;
		}
	}

	input[type="radio"],
	input[type="checkbox"] {
		position: absolute;
		z-index: -1;
		opacity: 0;
		display: none !important;

		&:checked {
			~.custom-option__label {
				&:before {
					background-color: var(--activeColor);
				}

				&:after {
					color: white;
					opacity: 1;
				}
			}

		}

		&:disabled {
			~.custom-option__label {
				opacity: 0.4;
				cursor: not-allowed;
				 
				&:before {
					background-color: #EEEEEE;
				}

				&:after {
					display: none;
				}
			}
		}

	}

	input[type="checkbox"]{
		~ .@{p}__label:before {
			border-radius: 4px;
		}
	}
}


// html example for inline
// <div class="custom-option custom-option--inline">
// <label class="custom-option__item">
// 	<input type="checkbox">
// 	<span class="custom-option__label">checkbox</span>
// </label>
// <label class="custom-option__item">
// 	<input type="checkbox">
// 	<span class="custom-option__label">checkbox</span>
// </label>
// </div>

// html example for block
// <div class="custom-option custom-option--block">
// <label class="custom-option__item">
// 	<input type="checkbox">
// 	<span class="custom-option__label">checkbox</span>
// </label>
// <label class="custom-option__item">
// 	<input type="checkbox">
// 	<span class="custom-option__label">checkbox</span>
// </label>
// </div>


.password-block {
	.input-group-addon {
		cursor: pointer;
	}
 
}