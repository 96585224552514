/*button css */

.btn {
	border-radius:4px;
	// padding: 10px 40px;
	position: relative;
	overflow: hidden;
	z-index:1;
	border:none;
	
	/*&:after {
		position:absolute;
		transform:translate(0, -50%);
		width:0;
		height:0;
		border-radius:4px;
		
		content:" ";
		margin:0 auto;
		top:50%;
		text-align:center;
		left:0;
		right:0;
		transition:all 0.5s ease-in-out;
		z-index:-1;
	}
	
	&.btn-primary {
		&:after {
		background-color:@brand-secondary;
		}
	}
	
	&.btn-secondary {
		&:after {
		background-color:@brand-primary;
		}
	}
	
	&.btn-default {
		&:after {
		background-color:lighten(@gray-light, 20%);
		}
	}
	
	&:hover {
		&:after {
			width:100%;
			height:100%;
		}
	}
	*/
	
}


 
.btn-secondary {
	background:@brand-secondary;
	color:white;
	 
	
	&:hover {
		background:darken(@brand-secondary, 10%);
		color:white;
		
	}
	&:focus {
		background:darken(@brand-secondary, 17%);
		color:white;
		
	}
}
.btn-primary {
	background: @brand-primary;
	i {
		color: white;
	}
	svg {
		fill:white;
	}
}

.btn-fluid {
	width:100%;
}
.btn-list {
	margin:0 -6px;
	
	.btn {
		margin:6px;
		
		i.fa {
			margin-right:4px;
		}
		
	}
}

.btn-block {
	// margin-left:-6px;
	// margin-right:-6px;
	
	.btn {
		margin:6px;
		display:block;
		i.fa {
			margin-right:4px;
		}
		
	}
}


.btn-outline {
	background-color:transparent;
	border:1px solid @gray-light;
}



.button-text {
    opacity: 1;
    transition: opacity 0.3s ease;
    .loading &{
        opacity: 0;

        ~.loader{
			 opacity: 1;
			 margin:0px;
			 svg {
				background-color: transparent;
				animation:none;
			 }
        }
    }
}

// .button-loader {
//       &.js-hide {
//         opacity: 0;
//     }
// }

.js-button-loader {
	.loader {
		position: relative;
		opacity: 0;
		transition: opacity 0.3s ease;
		position: absolute;
		top:0;
		left:0;
		display: flex;
		justify-content: center;
		align-items: center;
		width:100%;
		height: 100%;
		
	
	
		&:before {
			content: " ";
			display: inline-block;
		}
	
		svg {
			width:42px !important;
			height:auto !important;
			// @include size(rem(42) !important, auto !important);
		}
		lottie-animation-view {
			>div {
				margin-top: -10px !important;
			}
		}
	  
	}
	
	&.loading {
		cursor: not-allowed;
		pointer-events: none;
		opacity: 0.8;
	}
}



//custom file input
.file-control{
	display:none !important;
  }
  .label-file{
	width:100%;
	max-width: 300px; 
	height:40px;
	display:inline-block;
	position:relative;
	background-color:#fff;
	padding:5px 50px 5px 15px;
	border-radius:4px;
	border:1px solid @input-border;
	vertical-align: middle;
	overflow:hidden;
	line-height:30px;
	&:hover{
		&:after {
			background-color:@gray-mid;
			// color:white;
		}
	   }
  }
  .label-file:after{
	content: "Browse";
	position: absolute;
	background-color:#fff;
	right:0;
	top:0;
	height: 100%;
	display: inline-block;
	padding: 8px 15px 0;
	color:@text-color;
	line-height:20px;
	border-left:1px solid @input-border;
	cursor: pointer;
	
  }